import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';
import FunctionsBar from '../../components/Functions-Bar/FunctionsBar';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../context/Context';
import Loader from '../../components/Loader/Loader';
import axios from 'axios';
import { DeleteAllVipTipsModal } from '../../components/Modals/Modals';
import moment from 'moment';
import { AiTwotoneEdit } from 'react-icons/ai';
import { RiDeleteBin2Fill } from 'react-icons/ri';
import { toast } from 'react-toastify';

const DailyTips = () => {
  const {
    showProfileModal,
    baseURL,
    setMatches,
    deleteVipTips,
    setDeleteVipTips,
  } = useGlobalContext();
  const [searchTerm, setSearchTerm] = useState(``);
  const [loading, setLoading] = useState(false);
  const [tips, setTips] = useState([]);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`));
  const navigate = useNavigate();

  const getExpertSingles = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`${baseURL}/daily-tips`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      setTips(data.tips);
    } catch (error) {
      setLoading(false);
    }
  };

  // DELETE TICKET
  const deleteTicket = async (id) => {
    try {
      await axios.delete(`${baseURL}/daily-tips/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success(`Tip Deleted Successfully`);
      getExpertSingles();
    } catch (error) {
      toast.success(error.response.data.msg);
    }
  };

  useEffect(() => {
    getExpertSingles();
    setMatches([]);
    // eslint-disable-next-line
  }, [deleteVipTips]);

  // SEARCH TIPS
  const searchTips = () => {
    const tempArray = tips.filter(
      (tip) =>
        tip.homeTeam.toLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
        tip.awayTeam.toLowerCase().includes(searchTerm.toLocaleLowerCase())
    );
    setTips(tempArray);
    if (searchTerm === ``) {
      getExpertSingles();
    }
  };

  useEffect(() => {
    searchTips();
    // eslint-disable-next-line
  }, [searchTerm]);

  return (
    <main className='daily-tips-page'>
      <Navbar category={`Menu >`} currentPage={`Daily Tips`} />
      <Sidebar activePage={1} />

      <div className={`main-content ${showProfileModal ? `back` : null}`}>
        <FunctionsBar
          searchValue={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          searchPlaceholder={`Team Name`}
        >
          <button
            className='primary'
            onClick={() => navigate(`/daily-tips/new-tip`)}
          >
            Create Tip
          </button>
          <button className='red' onClick={() => setDeleteVipTips(true)}>
            Delete All
          </button>
        </FunctionsBar>
        <div className='table'>
          <div className='headers'>
            <p>Teams</p>
            <p>Competition</p>
            <p>Option</p>
            <p>Date Posted</p>
            <p>Actions</p>
          </div>

          {loading ? (
            <Loader />
          ) : (
            <>
              {tips &&
                tips.map((tip) => {
                  const {
                    _id,
                    awayTeam,
                    homeTeam,
                    matchTime,
                    predictionOption,
                    competitionName,
                  } = tip;

                  return (
                    <div className='tip-info' key={_id}>
                      <p className='teams'>
                        {homeTeam} vs {awayTeam}
                      </p>

                      <p className='competition'>{competitionName}</p>

                      <p className='option'>{predictionOption}</p>
                      <p className='date'>{moment(matchTime).format(`lll`)}</p>
                      <div className='btns'>
                        <AiTwotoneEdit
                          className='edit'
                          onClick={() => navigate(`/daily-tips/${_id}`)}
                        />
                        <RiDeleteBin2Fill
                          className='delete'
                          onClick={() => deleteTicket(_id)}
                        />
                      </div>
                    </div>
                  );
                })}
            </>
          )}
        </div>
      </div>
      {deleteVipTips && <DeleteAllVipTipsModal />}
    </main>
  );
};

export default DailyTips;
