import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../context/Context';
import { FaChevronLeft } from 'react-icons/fa';
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import Loader from '../../components/Loader/Loader';
import { CreateAd, DeleteAds, UpdateAd } from '../../components/Modals/Modals';
import { toast } from 'react-toastify';

const Ads = () => {
  const navigate = useNavigate();
  const { showProfileModal, baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(true);
  const [updateAd, setUpdateAd] = useState(false);
  const [adId, setAdId] = useState(``);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`));
  const [createAd, setCreateAd] = useState(false);
  const [deleteAds, setDeleteAds] = useState(false);
  const [ads, setAds] = useState([]);

  //   GET ALL ADS
  const getAds = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`${baseURL}/ads`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAds(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAds();
    // eslint-disable-next-line
  }, [updateAd]);

  const deleteAd = async (adId) => {
    try {
      const { data } = await axios.delete(`${baseURL}/ads/${adId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success(data.msg);
      getAds();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <main className='ads-page'>
      <Navbar category={`Management >`} currentPage={`Ads`} />
      <Sidebar activePage={12} />

      <div className={`main-content ${showProfileModal ? `back` : null}`}>
        <div className='links-btn'>
          <FaChevronLeft onClick={() => navigate(`/dashboard`)} />
          <h2 className='title'>Ad Links</h2>
        </div>

        <div className='create-del-btns'>
          <button className='primary' onClick={() => setCreateAd(true)}>
            Create Ad
          </button>
          <button className='red' onClick={() => setDeleteAds(true)}>
            Delete All Ads
          </button>
        </div>

        {loading ? (
          <div className='loader-container'>
            <Loader />
          </div>
        ) : (
          <>
            {ads.map((ad, adIndex) => {
              const { adLink, image, _id } = ad;
              return (
                <div className='link-details' key={adIndex}>
                  <h3 className='title'>Ad Link {adIndex + 1} Details</h3>
                  <div className='form-control'>
                    <label htmlFor=''>Ad Link</label>
                    <h3>{adLink}</h3>
                  </div>

                  <div className='form-control'>
                    <label htmlFor=''>Ad Image</label>
                    <div className='img'>
                      <img
                        src={`https://api.crspredictions.com${image}`}
                        alt=''
                      />
                    </div>
                  </div>
                  <div className='btns'>
                    <button
                      className='primary'
                      onClick={() => {
                        setAdId(_id);
                        setUpdateAd(true);
                      }}
                    >
                      Update Ad
                    </button>
                    <button
                      className='red'
                      onClick={() => {
                        deleteAd(_id);
                      }}
                    >
                      Delete Ad
                    </button>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
      {createAd && <CreateAd dismissFunc={setCreateAd} refreshFunc={getAds} />}

      {deleteAds && (
        <DeleteAds dismissFunc={setDeleteAds} refreshFunc={getAds} />
      )}
      {updateAd && <UpdateAd adId={adId} dismissFunc={setUpdateAd} />}
    </main>
  );
};

export default Ads;
