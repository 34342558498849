import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';
import { useNavigate, useParams } from 'react-router-dom';
import { useGlobalContext } from '../../context/Context';
import axios from 'axios';
import { FaChevronLeft } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import spinner from '../../assets/purple-btn-spinner.svg';
import Loader from '../../components/Loader/Loader';

const EditTodaysTip = () => {
  const { id } = useParams();
  const { showProfileModal, baseURL } = useGlobalContext();
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`));
  const [ticketDetails, setTicketDetails] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [logos, setLogos] = useState([]);
  const [competitions, setCompetitions] = useState([]);

  const fetchTicketDetails = async () => {
    try {
      const { data } = await axios.get(`${baseURL}/todays-tips/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTicketDetails(data.tip);
      setPageLoading(false);
    } catch (error) {
      setLoading(false);
      setPageLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      homeTeam: ``,
      awayTeam: ``,
      matchTime: ``,
      homeTeamLogo: ``,
      awayTeamLogo: ``,
      prediction: ``,
      competitionLogo: ``,
      competitionName: ``,
    },
    onSubmit() {
      updateTip();
    },
  });

  const fetchLogos = async () => {
    try {
      const { data } = await axios.get(`${baseURL}/clubs`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLogos(data.clubs);
    } catch (error) {}
  };

  const fetchCompetitons = async () => {
    try {
      const { data } = await axios.get(`${baseURL}/competitions`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCompetitions(data.competitions);
    } catch (error) {}
  };

  const updateTip = async () => {
    const {
      awayTeam,
      homeTeam,
      league,
      matchTime,
      awayTeamLogo,
      homeTeamLogo,
      competitionLogo,
      competitionName,
      prediction,
    } = formik.values;
    const formData = new FormData();
    if (homeTeam) {
      formData.append(`homeTeam`, homeTeam);
    }
    if (awayTeam) {
      formData.append(`awayTeam`, awayTeam);
    }
    if (league) {
      formData.append(`league`, league);
    }
    if (matchTime) {
      formData.append(`matchTime`, matchTime);
    }
    if (homeTeamLogo) {
      formData.append(`homeTeamLogo`, homeTeamLogo);
    }
    if (awayTeamLogo) {
      formData.append(`awayTeamLogo`, awayTeamLogo);
    }

    if (competitionLogo) {
      formData.append(`competitionLogo`, competitionLogo);
    }
    if (prediction) {
      formData.append(`prediction`, prediction);
    }

    if (competitionName) {
      formData.append(`competitionName`, competitionName);
    }

    try {
      setLoading(true);

      const { data } = await axios.patch(
        `${baseURL}/todays-tips/${id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      toast.success(data.msg);
      navigate(`/todays-tips`);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTicketDetails();
    fetchLogos();
    fetchCompetitons();
    //eslint-disable-next-line
  }, [id]);

  const { homeTeam, awayTeam, competitionName, prediction } = ticketDetails;

  return (
    <main className='edit-daily-tip-page'>
      <Navbar
        category={`Management > Today's Tips >`}
        categoryLink={`/todays-tips`}
        currentPage={`Edit Tip`}
      />
      <Sidebar activePage={3} />

      <div className={`main-content ${showProfileModal ? `back` : null}`}>
        <div className='title-btn'>
          <FaChevronLeft onClick={() => navigate(`/todays-tips`)} />
          <h2 className='title'>Edit Tip</h2>
        </div>

        {pageLoading ? (
          <div className='loader-container'>
            <Loader />
          </div>
        ) : (
          <>
            {/* TICKET INFORMATION BOX */}
            <div className='ticket-details'>
              <h3 className='title'>Tip Details</h3>

              <form action='' onSubmit={formik.handleSubmit}>
                {/* HOME TEAM */}
                <div className='form-control'>
                  <label
                    htmlFor='homeTeam'
                    className={
                      formik.errors.homeTeam && formik.touched.homeTeam
                        ? `error`
                        : ``
                    }
                  >
                    {formik.errors.homeTeam && formik.touched.homeTeam
                      ? formik.errors.homeTeam
                      : `Home Team`}{' '}
                    <span>*</span>
                  </label>
                  <input
                    type='text'
                    id='homeTeam'
                    value={formik.values.homeTeam}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name='homeTeam'
                    placeholder={homeTeam}
                  />
                </div>

                {/* HOME TEAM LOGO */}
                <div className='form-control'>
                  <label
                    htmlFor='homeTeamLogo'
                    className={
                      formik.errors.homeTeamLogo && formik.touched.homeTeamLogo
                        ? `error`
                        : ``
                    }
                  >
                    {formik.errors.homeTeamLogo && formik.touched.homeTeamLogo
                      ? formik.errors.homeTeamLogo
                      : `Home Team Logo`}{' '}
                    <span>*</span>
                  </label>
                  <select
                    name='homeTeamLogo'
                    id='homeTeamLogo'
                    value={formik.values.homeTeamLogo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option defaultChecked hidden>
                      Select Logo
                    </option>
                    {logos.map((logo) => {
                      const { _id, clubName, clubLogo } = logo;
                      return (
                        <option
                          value={`https://api.crspredictions.com${clubLogo}`}
                          key={_id}
                        >
                          {clubName} Logo
                        </option>
                      );
                    })}
                  </select>
                </div>

                {/* AWAY TEAM */}
                <div className='form-control'>
                  <label
                    htmlFor='awayTeam'
                    className={
                      formik.errors.awayTeam && formik.touched.awayTeam
                        ? `error`
                        : ``
                    }
                  >
                    {formik.errors.awayTeam && formik.touched.awayTeam
                      ? formik.errors.awayTeam
                      : `Away Team`}{' '}
                    <span>*</span>
                  </label>
                  <input
                    type='text'
                    id='awayTeam'
                    value={formik.values.awayTeam}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name='awayTeam'
                    placeholder={awayTeam}
                  />
                </div>

                {/* AWAY TEAM LOGO */}
                <div className='form-control'>
                  <label
                    htmlFor='awayTeamLogo'
                    className={
                      formik.errors.awayTeamLogo && formik.touched.awayTeamLogo
                        ? `error`
                        : ``
                    }
                  >
                    {formik.errors.awayTeamLogo && formik.touched.awayTeamLogo
                      ? formik.errors.awayTeamLogo
                      : `Away Team Logo`}{' '}
                    <span>*</span>
                  </label>
                  <select
                    name='awayTeamLogo'
                    id='awayTeamLogo'
                    value={formik.values.awayTeamLogo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option defaultChecked hidden>
                      Select Logo
                    </option>
                    {logos.map((logo) => {
                      const { _id, clubName, clubLogo } = logo;
                      return (
                        <option
                          value={`https://api.crspredictions.com${clubLogo}`}
                          key={_id}
                        >
                          {clubName} Logo
                        </option>
                      );
                    })}
                  </select>
                </div>

                {/* COMPETITION NAME */}
                <div className='form-control'>
                  <label
                    htmlFor='competitionName'
                    className={
                      formik.errors.competitionName &&
                      formik.touched.competitionName
                        ? `error`
                        : ``
                    }
                  >
                    {formik.errors.competitionName &&
                    formik.touched.competitionName
                      ? formik.errors.competitionName
                      : `Competition Name`}
                    <span>*</span>
                  </label>
                  <input
                    type='text'
                    id='competitionName'
                    value={formik.values.competitionName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name='competitionName'
                    placeholder={competitionName}
                  />
                </div>

                {/* COMPETITION LOGO */}
                <div className='form-control'>
                  <label
                    htmlFor='competitionLogo'
                    className={
                      formik.errors.competitionLogo &&
                      formik.touched.competitionLogo
                        ? `error`
                        : ``
                    }
                  >
                    {formik.errors.competitionLogo &&
                    formik.touched.competitionLogo
                      ? formik.errors.competitionLogo
                      : `Competition`}{' '}
                    <span>*</span>
                  </label>
                  <select
                    name='competitionLogo'
                    id='competitionLogo'
                    value={formik.values.competitionLogo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option defaultChecked hidden>
                      Select Logo
                    </option>
                    {competitions.map((logo) => {
                      const { _id, competitionName, competitionLogo } = logo;
                      return (
                        <option
                          value={`https://api.crspredictions.com${competitionLogo}`}
                          key={_id}
                        >
                          {competitionName}
                        </option>
                      );
                    })}
                  </select>
                </div>

                {/* PREDICTION */}
                <div className='form-control'>
                  <label
                    htmlFor='prediction'
                    className={
                      formik.errors.prediction && formik.touched.prediction
                        ? `error`
                        : ``
                    }
                  >
                    {formik.errors.prediction && formik.touched.prediction
                      ? formik.errors.prediction
                      : `Prediction Option`}{' '}
                    <span>*</span>
                  </label>
                  <select
                    name='prediction'
                    id='prediction'
                    value={formik.values.prediction}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option defaultChecked hidden>
                      {prediction}
                    </option>
                    <option value='Home'>Home</option>
                    <option value='Draw'>Draw</option>
                    <option value='Away'>Away</option>
                    <option value='Home/Draw'>Home/Draw</option>
                    <option value='Away/Draw'>Away/Draw</option>
                  </select>
                </div>

                {/* MATCH TIME */}
                <div className='form-control'>
                  <label
                    htmlFor='matchTime'
                    className={
                      formik.errors.matchTime && formik.touched.matchTime
                        ? `error`
                        : ``
                    }
                  >
                    {formik.errors.matchTime && formik.touched.matchTime
                      ? formik.errors.matchTime
                      : `Match Time`}{' '}
                    <span>*</span>
                  </label>
                  <input
                    name='matchTime'
                    id='matchTime'
                    type='datetime-local'
                    value={formik.values.matchTime}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>

                {/* SUBMIT BUTTON */}
                <button type='submit' className='primary'>
                  Update Tip {loading ? <img src={spinner} alt='' /> : null}
                </button>
              </form>
            </div>
          </>
        )}
      </div>
    </main>
  );
};

export default EditTodaysTip;
