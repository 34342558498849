import React, { useEffect, useState } from 'react';
import { useGlobalContext } from '../../context/Context';
import { useFormik } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { FaChevronLeft, FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

// COMPONENTS
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';

// ASSETS
import spinner from '../../assets/purple-btn-spinner.svg';
import { RiDeleteBin2Fill } from 'react-icons/ri';

const NewAccaTip = () => {
  const { showProfileModal, baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [addBookie, setAddBookie] = useState(false);
  const [bookies, setBookies] = useState([]);
  const [addMatch, setAddMatch] = useState(false);
  const [matches, setMatches] = useState([]);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`))
    ? JSON.parse(sessionStorage.getItem(`adminData`))
    : ``;
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      totalOdds: ``,
      stake: ``,
      win: ``,
      date: ``,
    },
    validationSchema: Yup.object({
      totalOdds: Yup.string().required(`Odds is Required`),
    }),
    onSubmit() {
      createFreeAccaTip();
    },
  });

  // CREATE TIP
  const createFreeAccaTip = async () => {
    try {
      setLoading(true);
      // eslint-disable-next-line
      const { data } = await axios.post(
        `${baseURL}/accumulator-tips`,
        { ...formik.values, bookies: bookies, matches: matches },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      toast.success(data.msg);
      navigate(`/accumulator-tips`);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.msg);
    }
  };

  const handleBookieDelete = (id) => {
    setBookies(() => bookies.filter((_, index) => index !== id));
  };

  const handleMatchDelete = (id) => {
    setMatches(() => matches.filter((_, index) => index !== id));
  };

  return (
    <main className='new-acca-tip-page'>
      <Navbar
        category={`Menu > Accumulator Tips >`}
        categoryLink={`/accumulator-tips`}
        currentPage={`New Ticket`}
      />
      <Sidebar activePage={2} />
      <div className={`main-content ${showProfileModal ? `back` : null}`}>
        <div className='title-btn'>
          <FaChevronLeft onClick={() => navigate(`/accumulator-tips`)} />
          <h2 className='title'>New Ticket</h2>
        </div>

        {/* TICKET DETAILS BOX */}
        <div className='ticket-details'>
          <h3 className='title'>Ticket Details</h3>

          <form action='' onSubmit={formik.handleSubmit}>
            {/* TOTAL ODDS */}
            <div className='form-control'>
              <label
                htmlFor='totalOdds'
                className={
                  formik.errors.totalOdds && formik.touched.totalOdds
                    ? `error`
                    : ``
                }
              >
                {formik.errors.totalOdds && formik.touched.totalOdds
                  ? formik.errors.totalOdds
                  : `Total Odds`}{' '}
                <span>*</span>
              </label>
              <input
                name='totalOdds'
                id='totalOdds'
                value={formik.values.totalOdds}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>

            {/* STAKE */}
            <div className='form-control'>
              <label
                htmlFor='stake'
                className={
                  formik.errors.stake && formik.touched.stake ? `error` : ``
                }
              >
                {formik.errors.stake && formik.touched.stake
                  ? formik.errors.stake
                  : `Stake`}{' '}
                <span>*</span>
              </label>
              <input
                name='stake'
                id='stake'
                value={formik.values.stake}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>

            {/* POTENTIAL WIN */}
            <div className='form-control'>
              <label
                htmlFor='win'
                className={
                  formik.errors.win && formik.touched.win ? `error` : ``
                }
              >
                {formik.errors.win && formik.touched.win
                  ? formik.errors.win
                  : `Potential Win`}
                <span>*</span>
              </label>
              <input
                name='win'
                id='win'
                value={formik.values.win}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>

            {/* MATCH DATE */}
            <div className='form-control'>
              <label
                htmlFor='date'
                className={
                  formik.errors.date && formik.touched.date ? `error` : ``
                }
              >
                {formik.errors.date && formik.touched.date
                  ? formik.errors.date
                  : `Match Date`}
              </label>
              <input
                name='date'
                id='date'
                type='date'
                value={formik.values.date}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>

            {/* BOOKIES */}
            <div className='bookies'>
              <h3 className='title'>Bookies</h3>
              {bookies && (
                <>
                  {bookies.map((bookie, bookieIndex) => {
                    const { link, code, name } = bookie;
                    return (
                      <div className='bookie' key={bookieIndex}>
                        <div className='name'>
                          <h5>{name}</h5>
                        </div>
                        <div className='code'>
                          <h5>{code}</h5>
                        </div>
                        <div className='link'>
                          <h5>{link}</h5>
                        </div>
                        <div className='del'>
                          <RiDeleteBin2Fill
                            onClick={() => handleBookieDelete(bookieIndex)}
                          />
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
            </div>

            <div className='add-bookie'>
              <button
                type='button'
                className='transparent'
                onClick={() => setAddBookie(true)}
              >
                Add Bookie
              </button>
            </div>

            {/* MATCHES */}
            <div className='matches'>
              <h3 className='title'>Matches</h3>
              {matches && (
                <>
                  {matches.map((match, matchIndex) => {
                    const {
                      homeTeam,
                      awayTeam,
                      prediction,
                      competitionName,
                      matchTime,
                      odds,
                    } = match;
                    return (
                      <div className='match' key={matchIndex}>
                        <div className='teams'>
                          <h5>
                            {homeTeam} vs {awayTeam}
                          </h5>
                        </div>
                        <div className='competition'>
                          <h5>{competitionName}</h5>
                        </div>
                        <div className='prediction'>
                          <h5>{prediction}</h5>
                        </div>
                        <div className='time'>
                          <h5>{moment(matchTime).format(`lll`)}</h5>
                        </div>
                        <div className='odds'>
                          <h5>{odds} odds</h5>
                        </div>
                        <div className='del'>
                          <RiDeleteBin2Fill
                            onClick={() => handleMatchDelete(matchIndex)}
                          />
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
            </div>

            <div className='add-match'>
              <button
                type='button'
                className='transparent'
                onClick={() => setAddMatch(true)}
              >
                Add Match
              </button>
            </div>

            {/* SUBMIT BUTTON */}
            <button type='submit' className='primary'>
              Create {loading ? <img src={spinner} alt='' /> : null}
            </button>
          </form>
        </div>
      </div>

      {addBookie && (
        <AddBookieModal
          bookies={bookies}
          setBookies={setBookies}
          setAddBookie={setAddBookie}
        />
      )}

      {addMatch && (
        <AddMatchModal
          matches={matches}
          setMatches={setMatches}
          setAddMatch={setAddMatch}
        />
      )}
    </main>
  );
};

export const AddBookieModal = ({ bookies, setBookies, setAddBookie }) => {
  const [bookie, setBookie] = useState({
    name: ``,
    link: ``,
    code: ``,
  });

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setBookie({ ...bookie, [name]: value });
  };

  const resetForm = () => {
    setBookie({
      name: ``,
      link: ``,
      code: ``,
    });
  };

  return (
    <div className='modal-container'>
      <div className='modal add-bookie'>
        {/* CLOSE MODAL BTN */}
        <div className='close-btn'>
          <FaTimes
            onClick={() => {
              setAddBookie(false);
              resetForm();
            }}
          />
        </div>

        {/* BOOKIE */}
        <div className='form-control'>
          <label htmlFor='name'>Bookie</label>
          <select name='name' id='name' onChange={handleChange}>
            <option defaultChecked hidden>
              Select Bookie
            </option>
            <option value='paripesa'>Paripesa</option>
            <option value='1xbet'>1xbet</option>
            <option value='bcgame'>Bcgame</option>
            <option value='betano'>Betano</option>
            <option value='22bet'>22Bet</option>
            <option value='stake'>Stake</option>
            <option value='sportybet'>Sportybet</option>
          </select>
        </div>

        {/* BOOKING CODE */}
        <div className='form-control'>
          <label htmlFor='code'>Booking Code</label>
          <input type='text' id='code' name='code' onChange={handleChange} />
        </div>

        {/* BOOKING LINK */}
        <div className='form-control'>
          <label htmlFor='link'>Booking Link</label>
          <input type='text' id='link' name='link' onChange={handleChange} />
        </div>

        <button
          className='primary'
          disabled={bookie.code && bookie.link && bookie.name ? false : true}
          onClick={() => {
            setBookies([...bookies, bookie]);
            setAddBookie(false);
            resetForm();
          }}
        >
          Add Bookie
        </button>
      </div>
    </div>
  );
};

export const AddMatchModal = ({ matches, setMatches, setAddMatch }) => {
  const { baseURL } = useGlobalContext();

  const [match, setMatch] = useState({
    homeTeam: ``,
    homeTeamLogo: ``,
    awayTeam: ``,
    awayTeamLogo: ``,
    competitionLogo: ``,
    competitionName: ``,
    matchTime: ``,
    prediction: ``,
    odds: ``,
  });

  const [logos, setLogos] = useState([]);
  const [competitions, setCompetitions] = useState([]);

  const { token } = JSON.parse(sessionStorage.getItem(`adminData`));

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setMatch({ ...match, [name]: value });
  };

  const fetchLogos = async () => {
    try {
      const { data } = await axios.get(`${baseURL}/clubs`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLogos(data.clubs);
    } catch (error) {}
  };

  const fetchCompetitons = async () => {
    try {
      const { data } = await axios.get(`${baseURL}/competitions`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCompetitions(data.competitions);
    } catch (error) {}
  };

  const resetForm = () => {
    setMatch({
      homeTeam: ``,
      homeTeamLogo: ``,
      awayTeam: ``,
      awayTeamLogo: ``,
      matchTime: ``,
      prediction: ``,
      competitionLogo: ``,
      competitionName: ``,
      odds: ``,
    });
  };

  useEffect(() => {
    fetchLogos();
    fetchCompetitons();
    // eslint-disable-next-line
  }, []);

  return (
    <div className='modal-container'>
      <div className='modal add-bookie'>
        {/* CLOSE MODAL BTN */}
        <div className='close-btn'>
          <FaTimes
            onClick={() => {
              setAddMatch(false);
              resetForm();
            }}
          />
        </div>

        {/* HOME TEAM */}
        <div className='form-control'>
          <label htmlFor='code'>Home Team</label>
          <input
            type='text'
            id='homeTeam'
            name='homeTeam'
            onChange={handleChange}
          />
        </div>

        {/* HOME TEAM LOGO */}
        <div className='form-control'>
          <label htmlFor='homeTeamLogo'>
            Home Team Logo
            <span>*</span>
          </label>
          <select name='homeTeamLogo' id='homeTeamLogo' onChange={handleChange}>
            <option defaultChecked hidden>
              Select Logo
            </option>
            {logos.map((logo) => {
              const { _id, clubName, clubLogo } = logo;
              return (
                <option
                  value={`https://api.crspredictions.com${clubLogo}`}
                  key={_id}
                >
                  {clubName} Logo
                </option>
              );
            })}
          </select>
        </div>

        {/* AWAY TEAM */}
        <div className='form-control'>
          <label htmlFor='code'>Away Team</label>
          <input
            type='text'
            id='awayTeam'
            name='awayTeam'
            onChange={handleChange}
          />
        </div>

        {/* AWAY TEAM LOGO */}
        <div className='form-control'>
          <label htmlFor='awayTeamLogo'>
            Away Team Logo
            <span>*</span>
          </label>
          <select name='awayTeamLogo' id='awayTeamLogo' onChange={handleChange}>
            <option defaultChecked hidden>
              Select Logo
            </option>
            {logos.map((logo) => {
              const { _id, clubName, clubLogo } = logo;
              return (
                <option
                  value={`https://api.crspredictions.com${clubLogo}`}
                  key={_id}
                >
                  {clubName} Logo
                </option>
              );
            })}
          </select>
        </div>

        {/* ODDS */}
        <div className='form-control'>
          <label htmlFor='odds'>
            Odds
            <span>*</span>
          </label>
          <input name='odds' id='odds' onChange={handleChange} />
        </div>

        {/* COMPETITION NAME */}
        <div className='form-control'>
          <label htmlFor='competitionName'>
            Competition Name
            <span>*</span>
          </label>
          <input
            type='text'
            id='competitionName'
            onChange={handleChange}
            name='competitionName'
          />
        </div>

        {/* COMPETITION LOGO */}
        <div className='form-control'>
          <label htmlFor='competitionLogo'>
            Competition Logo
            <span>*</span>
          </label>
          <select
            name='competitionLogo'
            id='competitionLogo'
            onChange={handleChange}
          >
            <option defaultChecked hidden>
              Select Logo
            </option>
            {competitions.map((logo) => {
              const { _id, competitionName, competitionLogo } = logo;
              return (
                <option
                  value={`https://api.crspredictions.com${competitionLogo}`}
                  key={_id}
                >
                  {competitionName} Logo
                </option>
              );
            })}
          </select>
        </div>

        {/* MATCH TIME */}
        <div className='form-control'>
          <label htmlFor='matchTime'>Match Time</label>
          <input
            name='matchTime'
            id='matchTime'
            type='datetime-local'
            onChange={handleChange}
          />
        </div>

        {/* PREDICTION */}
        <div className='form-control'>
          <label htmlFor='link'>Prediction</label>
          <input
            type='text'
            id='prediction'
            name='prediction'
            onChange={handleChange}
          />
        </div>

        <button
          className='primary'
          disabled={
            match.homeTeam &&
            match.awayTeam &&
            match.matchTime &&
            match.homeTeamLogo &&
            match.awayTeamLogo &&
            match.competitionName &&
            match.competitionLogo &&
            match.odds &&
            match.prediction
              ? false
              : true
          }
          onClick={() => {
            setMatches([...matches, match]);
            setAddMatch(false);
            resetForm();
          }}
        >
          Add Match
        </button>
      </div>
    </div>
  );
};

export default NewAccaTip;
