import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';
import { useNavigate, useParams } from 'react-router-dom';
import { useGlobalContext } from '../../context/Context';
import axios from 'axios';
import { FaChevronLeft } from 'react-icons/fa';
import Loader from '../../components/Loader/Loader';
import { toast } from 'react-toastify';
import spinner from '../../assets/white-spinner.svg';
import { UpdateBlogPost } from '../../components/Modals/Modals';

const ViewPost = () => {
  const { id } = useParams();
  const { showProfileModal, baseURL, updateBlogPost, setUpdateBlogPost } =
    useGlobalContext();
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`));
  const [post, setPost] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const navigate = useNavigate();

  const fetchPostDetails = async () => {
    try {
      const { data } = await axios.get(`${baseURL}/news/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setPost(data[0]);
      setLoading(false);
      if (data.length < 1) {
        navigate(`/news-updates`);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPostDetails();
    //eslint-disable-next-line
  }, [id, updateBlogPost]);

  const deletePost = async (id) => {
    try {
      setDeleteLoading(true);
      await axios.delete(`${baseURL}/news/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success(`Blog Post deleted successfully`);
      navigate(`/news-updates`);
      setDeleteLoading(false);
    } catch (error) {
      console.log(error.response.data.msg);
      setDeleteLoading(false);
    }
  };

  return (
    <main className='view-vip-tip-page'>
      <Navbar
        category={`Management > News Updates >`}
        categoryLink={`/news-updates`}
        currentPage={`View Post`}
      />
      <Sidebar activePage={8} />

      <div className={`main-content ${showProfileModal ? `back` : null}`}>
        <div className='post-btn'>
          <FaChevronLeft onClick={() => navigate(`/news-updates`)} />
          <h2 className='title'>Blog Post</h2>
        </div>

        {/* CUSTOMER INFORMATION BOX */}
        {loading ? (
          <Loader />
        ) : (
          <div className='post-details'>
            {/* TITLE */}
            <div className='form-control'>
              <label htmlFor=''>Post Title</label>
              <h3>{post?.title}</h3>
            </div>

            {/* CONTENT */}
            <div className='form-control'>
              <label htmlFor=''>Post Content (Batch One)</label>
              <div
                className='content'
                dangerouslySetInnerHTML={{ __html: post?.contentOne }}
              ></div>
            </div>

            {/* CONTENT */}
            <div className='form-control'>
              <label htmlFor=''>Post Content (Batch Two)</label>
              <div
                className='content'
                dangerouslySetInnerHTML={{ __html: post?.contentTwo }}
              ></div>
            </div>

            {/* IMAGE */}
            <div className='form-control'>
              <label htmlFor=''>Post Image</label>
              <div className='img'>
                <img
                  src={
                    post ? `https://api.crspredictions.com${post.image}` : null
                  }
                  alt={post?.title}
                />
              </div>
            </div>

            <div className='btns'>
              <button
                className='primary'
                type='button'
                onClick={() => setUpdateBlogPost(true)}
              >
                Update Post
              </button>
              <button className='red' onClick={() => deletePost(post?._id)}>
                Delete Post
                {deleteLoading && <img src={spinner} alt='' />}
              </button>
            </div>
          </div>
        )}
      </div>
      {updateBlogPost && <UpdateBlogPost postDetails={post} />}
    </main>
  );
};

export default ViewPost;
