import React, { useEffect, useState } from 'react';
import { useGlobalContext } from '../../context/Context';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

// COMPONENTS
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';

// ASSETS
import spinner from '../../assets/purple-btn-spinner.svg';
import { toast } from 'react-toastify';
import { FaChevronLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const AddPlayer = () => {
  const { showProfileModal, baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(`page-loading`);
  const [leaderboards, setLeaderboards] = useState([]);
  const [leaderboardId, setLeaderboardId] = useState(``);

  const { token } = JSON.parse(sessionStorage.getItem(`adminData`));
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      points: ``,
      username: ``,
    },
    validationSchema: Yup.object({
      points: Yup.number().required(`Please provide player points`),
      username: Yup.string().required(`Please provide player username`),
    }),
    onSubmit() {
      addPlayer();
    },
  });

  const getLeaderboards = async () => {
    try {
      setLoading(`page-loading`);
      const { data } = await axios.get(`${baseURL}/leaderboard`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(``);
      setLeaderboards(data.leaderboards);
    } catch (error) {
      setLoading(``);
    }
  };

  const addPlayer = async () => {
    try {
      setLoading(`add-player-loading`);
      const { data } = await axios.post(
        `${baseURL}/leaderboard/add-player/${leaderboardId}`,
        formik.values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(``);
      toast.success(data.msg);
      navigate(`/leaderboard`);
    } catch (error) {
      setLoading(``);
      toast.error(error.response.data.msg);
    }
  };

  useEffect(() => {
    getLeaderboards();
    // eslint-disable-next-line
  }, []);

  return (
    <main className='add-player-page'>
      <Navbar
        category={`Menu > Leaderboard >`}
        categoryLink={`/leaderboard`}
        currentPage={`Add Player`}
      />
      <Sidebar activePage={5} />
      <div className={`main-content ${showProfileModal ? `back` : null}`}>
        <div className='title-btn'>
          <FaChevronLeft onClick={() => navigate(`/leaderboard`)} />
          <h2 className='title'>Add Player</h2>
        </div>

        {/* TICKET DETAILS BOX */}
        <div className='player-details'>
          <h3 className='title'>Player Details</h3>

          <form action='' onSubmit={formik.handleSubmit}>
            {/* LEADERBOARD */}
            <div className='form-control'>
              <label htmlFor='leaderboard'>
                Select Leaderboard
                <span>*</span>
              </label>
              <select
                name='leaderboard'
                id='leaderboard'
                onChange={(e) => setLeaderboardId(e.target.value)}
                required
              >
                <option defaultChecked hidden>
                  Select Leaderboard
                </option>
                {leaderboards.map((leaderboard) => {
                  const { _id, name } = leaderboard;
                  return (
                    <option value={_id} key={_id}>
                      {name}
                    </option>
                  );
                })}
              </select>
            </div>

            {/* USERNAME */}
            <div className='form-control'>
              <label
                htmlFor='username'
                className={
                  formik.errors.username && formik.touched.username
                    ? `error`
                    : ``
                }
              >
                {formik.errors.username && formik.touched.username
                  ? formik.errors.username
                  : `Username`}{' '}
                <span>*</span>
              </label>
              <input
                type='text'
                id='username'
                value={formik.values.username}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name='username'
              />
            </div>

            {/* POINTS */}
            <div className='form-control'>
              <label
                htmlFor='points'
                className={
                  formik.errors.points && formik.touched.points ? `error` : ``
                }
              >
                {formik.errors.points && formik.touched.points
                  ? formik.errors.points
                  : `Points`}{' '}
                <span>*</span>
              </label>
              <input
                type='text'
                id='points'
                value={formik.values.points}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name='points'
              />
            </div>

            {/* SUBMIT BUTTON */}
            <button type='submit' className='primary'>
              Add Player{' '}
              {loading === `add-player-loading` ? (
                <img src={spinner} alt='' />
              ) : null}
            </button>
          </form>
        </div>
      </div>
    </main>
  );
};

export default AddPlayer;
