import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';
import FunctionsBar from '../../components/Functions-Bar/FunctionsBar';
import { Link, useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../context/Context';
import { RiDeleteBin2Fill } from 'react-icons/ri';
import { AiTwotoneEdit } from 'react-icons/ai';
import Loader from '../../components/Loader/Loader';
import axios from 'axios';
import { toast } from 'react-toastify';
import { DeleteAllFreeAccaTiipsModal } from '../../components/Modals/Modals';
import moment from 'moment';

const AccumulatorTips = () => {
  const { showProfileModal, baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [AccaTips, setAccaTips] = useState([]);
  const [deleteTips, setDeleteTips] = useState(false);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`));
  const navigate = useNavigate();

  const getAccumulatorTips = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`${baseURL}/accumulator-tips`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      setAccaTips(data.tips);
    } catch (error) {
      setLoading(false);
    }
  };

  // DELETE TICKET
  const deleteTicket = async (id) => {
    try {
      // eslint-disable-next-line
      const { data } = await axios.delete(`${baseURL}/accumulator-tips/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success(`Tip Deleted Successfully`);
      getAccumulatorTips();
    } catch (error) {
      toast.success(error.response.data.msg);
    }
  };

  useEffect(() => {
    getAccumulatorTips();
    // eslint-disable-next-line
  }, []);

  return (
    <main className='acca-tips-page'>
      <Navbar category={`Menu >`} currentPage={`Accumulator Tips`} />
      <Sidebar activePage={2} />

      <div className={`main-content ${showProfileModal ? `back` : null}`}>
        <FunctionsBar>
          <button
            className='primary'
            onClick={() => navigate(`/accumulator-tips/new-tip`)}
          >
            Create Tip
          </button>
          <button className='red' onClick={() => setDeleteTips(true)}>
            Delete All
          </button>
        </FunctionsBar>
        <div className='table'>
          <div className='headers'>
            <p>Total Odds</p>
            <p>Date</p>
            <p>No. of Matches</p>
            <p>Action</p>
          </div>

          {loading ? (
            <Loader />
          ) : (
            <>
              {AccaTips &&
                AccaTips.map((tip) => {
                  const { _id, totalOdds, matches, date } = tip;

                  return (
                    <div className='tip-info' key={_id}>
                      <Link className='no-of-matches'>{totalOdds}</Link>
                      <Link className='no-of-bookies'>
                        {moment(date).format(`MMM Do YYYY`)}
                      </Link>
                      <Link className='no-of-matches'>{matches.length}</Link>
                      <div className='btns'>
                        <AiTwotoneEdit
                          className='edit'
                          onClick={() => navigate(`/accumulator-tips/${_id}`)}
                        />
                        <RiDeleteBin2Fill
                          className='delete'
                          onClick={() => deleteTicket(_id)}
                        />
                      </div>
                    </div>
                  );
                })}
            </>
          )}
        </div>
      </div>
      {deleteTips && (
        <DeleteAllFreeAccaTiipsModal
          dismissFunc={setDeleteTips}
          refreshFunc={getAccumulatorTips}
        />
      )}
    </main>
  );
};

export default AccumulatorTips;
