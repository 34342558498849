import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';
import { useGlobalContext } from '../../context/Context';
import { useGetRequest } from '../../custom-hooks/useGetRequest';
import { useEffect } from 'react';
import { FaGifts, FaUsers } from 'react-icons/fa';
import { BsNewspaper } from 'react-icons/bs';
import { RiMessage2Fill } from 'react-icons/ri';

const Dashboard = () => {
  const { showProfileModal, baseURL } = useGlobalContext();

  // GET ALL CUSTOMERS
  const { data: customers, getDefault: getAllCustomers } = useGetRequest(
    `${baseURL}/users/count`
  );

  // GET ALL FREE ACCA TIPS
  const { data: messages, getDefault: getAllMessages } = useGetRequest(
    `${baseURL}/contact-us`
  );

  // GET ALL NEWS POSTS
  const {
    data: { news },
    getDefault: getNewsPosts,
  } = useGetRequest(`${baseURL}/news/count`);
  // GET ALL AFFILIATES
  const { data: affiliates, getDefault: getAffiliates } = useGetRequest(
    `${baseURL}/affiliates`
  );

  useEffect(() => {
    getAllCustomers();
    getAllMessages();
    getNewsPosts();
    getAffiliates();
    // eslint-disable-next-line
  }, []);

  return (
    <main className='dashboard'>
      <Navbar category={`Menu >`} currentPage={`Dashboard`} />
      <Sidebar activePage={0} />
      <section className={`main-content ${showProfileModal ? `back` : null}`}>
        {/* CARDS SECTION */}
        <section className='cards'>
          <div className='card'>
            <div className='img'>
              <FaUsers />
            </div>
            <div className='info'>
              <h4 className='name'>Customers</h4>
              <h3 className='num'>{customers.users && customers.users}</h3>
            </div>
          </div>

          <div className='card'>
            <div className='img'>
              <RiMessage2Fill />
            </div>
            <div className='info'>
              <h4 className='name'>Total Messages</h4>
              <h3 className='num'>{messages.count && messages.count}</h3>
            </div>
          </div>

          <div className='card'>
            <div className='img'>
              <BsNewspaper />
            </div>
            <div className='info'>
              <h4 className='name'>Total News Posts</h4>
              <h3 className='num'>{news}</h3>
            </div>
          </div>

          <div className='card'>
            <div className='img'>
              <FaGifts />
            </div>
            <div className='info'>
              <h4 className='name'>Total affiliates</h4>
              <h3 className='num'>{affiliates.count}</h3>
            </div>
          </div>
        </section>
      </section>
    </main>
  );
};

export default Dashboard;
