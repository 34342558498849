import { Slide, ToastContainer } from 'react-toastify';
import {
  AccumulatorTips,
  AddPlayer,
  Admins,
  Ads,
  Affiliates,
  Clubs,
  Competitions,
  Customers,
  DailyTips,
  Dashboard,
  EditAccaTip,
  EditDailyTip,
  EditPlayer,
  EditTodaysTip,
  Extras,
  Gifs,
  Leaderboard,
  Login,
  MatchOfTheDay,
  Messages,
  NewAccaTip,
  NewAdmin,
  NewDailyTip,
  NewMatchOfTheDay,
  NewPost,
  NewTodaysTip,
  NewsUpdates,
  Popups,
  SocialMediaLinks,
  TodaysTips,
  ViewCustomer,
  ViewMessage,
  ViewPost,
} from './pages';
import { Routes, Route } from 'react-router-dom';
import PrivateRoute from './pages/PrivateRoute';
import { useEffect } from 'react';
import { useGlobalContext } from './context/Context';

function App() {
  const { wakeServer } = useGlobalContext();

  useEffect(() => {
    wakeServer();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Routes>
        <Route path='/' element={<Login />} />

        <Route element={<PrivateRoute />}>
          <Route path='/dashboard' element={<Dashboard />} />

          <Route path='/daily-tips' element={<DailyTips />} />
          <Route path='/daily-tips/new-tip' element={<NewDailyTip />} />
          <Route path='/daily-tips/:id' element={<EditDailyTip />} />

          <Route path='/todays-tips' element={<TodaysTips />} />
          <Route path='/todays-tips/new-tip' element={<NewTodaysTip />} />
          <Route path='/todays-tips/:id' element={<EditTodaysTip />} />

          <Route path='/accumulator-tips' element={<AccumulatorTips />} />
          <Route path='/accumulator-tips/:id' element={<EditAccaTip />} />
          <Route path='/accumulator-tips/new-tip' element={<NewAccaTip />} />

          <Route path='/news-updates' element={<NewsUpdates />} />
          <Route path='/news-updates/:id' element={<ViewPost />} />
          <Route path='/news-updates/new-post' element={<NewPost />} />

          <Route path='/match-of-the-day' element={<MatchOfTheDay />} />
          <Route
            path='/match-of-the-day/new-match'
            element={<NewMatchOfTheDay />}
          />

          <Route path='/leaderboard' element={<Leaderboard />} />
          <Route path='/leaderboard/add-player' element={<AddPlayer />} />
          <Route
            path='/leaderboard/:id/player/:playerId'
            element={<EditPlayer />}
          />

          <Route path='/customers' element={<Customers />} />
          <Route path='/customers/:id' element={<ViewCustomer />} />
          <Route path='/clubs' element={<Clubs />} />
          <Route path='/competitions' element={<Competitions />} />
          <Route path='/social-media-links' element={<SocialMediaLinks />} />
          <Route path='/ads' element={<Ads />} />
          <Route path='/admins' element={<Admins />} />
          <Route path='/admins/new-admin' element={<NewAdmin />} />
          <Route path='/admins/:id' element={<Admins />} />
          <Route path='/extras' element={<Extras />} />
          <Route path='/messages' element={<Messages />} />
          <Route path='/messages/:id' element={<ViewMessage />} />
          <Route path='/affiliates' element={<Affiliates />} />
          <Route path='/pop-ups' element={<Popups />} />
          <Route path='/gifs' element={<Gifs />} />
        </Route>
      </Routes>
      <ToastContainer transition={Slide} autoClose={1000} />
    </>
  );
}

export default App;
