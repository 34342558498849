import React, { useEffect } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../context/Context';
import { FaChevronLeft, FaEye, FaEyeSlash } from 'react-icons/fa';
import { useState } from 'react';
import axios from 'axios';
import 'react-loading-skeleton/dist/skeleton.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import spinner from '../../assets/purple-btn-spinner.svg';

const Extras = () => {
  const navigate = useNavigate();
  const { showProfileModal, baseURL, revealPassword } = useGlobalContext();
  const [showPassword, setShowPassword] = useState(false);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`));
  const [loading, setLoading] = useState(``);
  const [trustedUsers, setTrustedUsers] = useState([]);
  const [bountyLink, setBountyLink] = useState([]);

  // FETCH CURRENT TRUSTED USERS
  const getTrustedUsers = async () => {
    try {
      const { data } = await axios.get(`${baseURL}/trusted-users`);
      setTrustedUsers(data.trustedUsers[0]);
      toast.success(data.msg);
    } catch (error) {}
  };

  // FETCH CURRENT BOUNTY LINK
  const getBountyLink = async () => {
    try {
      const { data } = await axios.get(`${baseURL}/bounty-link`);
      setBountyLink(data.bountyLinks[0]);
      toast.success(data.msg);
    } catch (error) {}
  };

  // UPDATE NO. OF TRUSTED USER
  const formikTrustedUsers = useFormik({
    initialValues: {
      noOfTrustedUsers: ``,
    },
    validationSchema: Yup.object({
      noOfTrustedUsers: Yup.string().required(
        `No. of trusted users is required`
      ),
    }),
    onSubmit() {
      updateTrustedUsers();
    },
  });

  const updateTrustedUsers = async () => {
    try {
      setLoading(`trusted-users loading`);
      const { data } = await axios.patch(
        `${baseURL}/trusted-users/${trustedUsers._id}`,
        formikTrustedUsers.values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(data.msg);
      setLoading(``);
    } catch (error) {
      setLoading(``);
      console.log(error.response.data);
    }
  };

  // UPDATE BOUNTY LINK
  const formikBountyLink = useFormik({
    initialValues: {
      link: ``,
    },
    validationSchema: Yup.object({
      link: Yup.string().required(`Bounty link is required`),
    }),
    onSubmit() {
      updateBountyLink();
    },
  });

  const updateBountyLink = async () => {
    try {
      setLoading(`bounty-link-loading`);
      const { data } = await axios.patch(
        `${baseURL}/bounty-link/${bountyLink._id}`,
        formikBountyLink.values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(data.msg);
      setLoading(``);
      formikBountyLink.values.link = ``;
      getBountyLink();
    } catch (error) {
      setLoading(``);
      console.log(error.response.data);
      toast.error(error.response.data);
    }
  };

  // UPDATE ADMIN PASSWORD
  const formikPassword = useFormik({
    initialValues: {
      password: ``,
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, `Minimum of 8 characters`)
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          'At least 1 special character and a number'
        )
        .required(`Password is required`),
    }),
    onSubmit() {
      updatePassword();
    },
  });

  const updatePassword = async () => {
    try {
      setLoading(`password-update loading`);
      const { data } = await axios.patch(
        `${baseURL}/auth/admin/update-password`,
        formikPassword.values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(`Password Updated`);
      setLoading(``);
      console.log(data);
    } catch (error) {
      setLoading(``);
      console.log(error.response.data);
    }
  };

  useEffect(() => {
    getTrustedUsers();
    getBountyLink();
    // eslint-disable-next-line
  }, []);

  return (
    <main className='extras-page'>
      <Navbar category={`Management >`} currentPage={`Extras`} />
      <Sidebar activePage={19} />

      <div className={`main-content ${showProfileModal ? `back` : null}`}>
        <div className='links-btn'>
          <FaChevronLeft onClick={() => navigate(`/dashboard`)} />
          <h2 className='title'>Extras</h2>
        </div>

        {/* UPDATE NO. TRUSTED USERS */}
        <div className='link-details'>
          <h3 className='title'>Update No. of Trusted Users</h3>
          <form action='' onSubmit={formikTrustedUsers.handleSubmit}>
            <div className='form-control'>
              <label
                htmlFor='total-tips'
                className={
                  formikTrustedUsers.touched.noOfTrustedUsers &&
                  formikTrustedUsers.errors.noOfTrustedUsers
                    ? `error`
                    : ``
                }
              >
                {formikTrustedUsers.errors.noOfTrustedUsers &&
                formikTrustedUsers.touched.noOfTrustedUsers
                  ? formikTrustedUsers.errors.noOfTrustedUsers
                  : `No. of Trusted Users`}
              </label>
              <input
                type='text'
                id='noOfTrustedUsers'
                name='noOfTrustedUsers'
                value={formikTrustedUsers.values.noOfTrustedUsers}
                onChange={formikTrustedUsers.handleChange}
                onBlur={formikTrustedUsers.handleBlur}
                placeholder={trustedUsers.noOfTrustedUsers}
              />
            </div>
            <button className='primary' type='submit'>
              Update Trusted Users{' '}
              {loading === `trusted-users loading` ? (
                <img src={spinner} alt='' />
              ) : null}
            </button>
          </form>
        </div>

        {/* UPDATE BOUNTY LINK */}
        <div className='link-details'>
          <h3 className='title'>Update Bounty link</h3>
          <form action='' onSubmit={formikBountyLink.handleSubmit}>
            <div className='form-control'>
              <label
                htmlFor='link'
                className={
                  formikBountyLink.touched.link && formikBountyLink.errors.link
                    ? `error`
                    : ``
                }
              >
                {formikBountyLink.errors.link && formikBountyLink.touched.link
                  ? formikBountyLink.errors.link
                  : `Bounty link`}
              </label>
              <input
                type='text'
                id='link'
                name='link'
                value={formikBountyLink.values.link}
                onChange={formikBountyLink.handleChange}
                onBlur={formikBountyLink.handleBlur}
                placeholder={bountyLink.link}
              />
            </div>
            <button className='primary' type='submit'>
              Update Bounty link{' '}
              {loading === `bounty-link-loading` ? (
                <img src={spinner} alt='' />
              ) : null}
            </button>
          </form>
        </div>

        {/* UPDATE PASSWORD */}
        <div className='link-details'>
          <h3 className='title'>Update Password</h3>
          <form action='' onSubmit={formikPassword.handleSubmit}>
            <div className='form-control'>
              <label
                htmlFor='total-tips'
                className={
                  formikPassword.touched.password &&
                  formikPassword.errors.password
                    ? `error`
                    : ``
                }
              >
                {formikPassword.errors.password &&
                formikPassword.touched.password
                  ? formikPassword.errors.password
                  : `New Password`}
              </label>
              <div className='password-container'>
                <input
                  type='password'
                  id='password'
                  name='password'
                  value={formikPassword.values.password}
                  onChange={formikPassword.handleChange}
                  onBlur={formikPassword.handleBlur}
                />
                {showPassword ? (
                  <FaEyeSlash
                    className='icon'
                    onClick={() => {
                      revealPassword(`password`);
                      setShowPassword(!showPassword);
                    }}
                  />
                ) : (
                  <FaEye
                    className='icon'
                    onClick={() => {
                      revealPassword(`password`);
                      setShowPassword(!showPassword);
                    }}
                  />
                )}
              </div>
            </div>
            <button className='primary' type='submit'>
              Update Password{' '}
              {loading === `password-update loading` ? (
                <img src={spinner} alt='' />
              ) : null}
            </button>
          </form>
        </div>
      </div>
    </main>
  );
};

export default Extras;
