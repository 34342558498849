import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';
import { useNavigate, useParams } from 'react-router-dom';
import { useGlobalContext } from '../../context/Context';
import axios from 'axios';
import { FaChevronLeft } from 'react-icons/fa';
import { DeleteCustomer } from '../../components/Modals/Modals';
import Loader from '../../components/Loader/Loader';
import moment from 'moment';

const ViewCustomer = () => {
  const { id } = useParams();
  const {
    showProfileModal,
    baseURL,
    updateCustomerVipStatus,
    deleteCustomer,
    setDeleteCustomer,
  } = useGlobalContext();
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`));
  const [customerDetails, setCustomerDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // GET CUSTOMER DEDTAILS
  const fetchCustomerDetails = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`${baseURL}/users/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCustomerDetails(data.user);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomerDetails();
    //eslint-disable-next-line
  }, [id, updateCustomerVipStatus]);

  const { name, email, _id, telegramId, phoneNumber, createdAt } =
    customerDetails;

  return (
    <main className='view-customer-page'>
      <Navbar
        category={`Management > Customers >`}
        categoryLink={`/customers`}
        currentPage={`View Customer`}
      />
      <Sidebar activePage={9} />

      <div className={`main-content ${showProfileModal ? `back` : null}`}>
        <div className='title-btn'>
          <FaChevronLeft onClick={() => navigate(`/customers`)} />
          <h2 className='title'>Customer</h2>
        </div>

        {loading ? (
          <div className='loader-container'>
            <Loader />
          </div>
        ) : (
          <>
            {/* CUSTOMER INFORMATION BOX */}
            <div className='customer-details'>
              <h3 className='title'>Customer Details</h3>

              <div className='form-control'>
                <label htmlFor='first-name'>Name</label>
                <h3>{name ? name : `Loading...`}</h3>
              </div>

              <div className='form-control'>
                <label htmlFor='email'>Email</label>
                <h3 className='email'>{email ? email : `Loading`}</h3>
              </div>

              <div className='form-control'>
                <label htmlFor='email'>Telegram ID</label>
                <h3 className='telegram'>
                  {telegramId ? telegramId : `not provided`}
                </h3>
              </div>

              <div className='form-control'>
                <label htmlFor='email'>Phone Number</label>
                <h3>{phoneNumber ? phoneNumber : `Loading...`}</h3>
              </div>

              <div className='form-control'>
                <label htmlFor='email'>Registration Date</label>
                <h3>
                  {createdAt ? moment(createdAt).format(`LLL`) : `Loading...`}
                </h3>
              </div>

              <div className='btns'>
                <button
                  className='red'
                  type='button'
                  onClick={() => setDeleteCustomer(true)}
                >
                  Delete Customer
                </button>
              </div>
            </div>
          </>
        )}
      </div>
      {deleteCustomer && <DeleteCustomer userId={_id} />}
    </main>
  );
};

export default ViewCustomer;
