import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';
import { useNavigate, useParams } from 'react-router-dom';
import { useGlobalContext } from '../../context/Context';
import axios from 'axios';
import { FaChevronLeft } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import spinner from '../../assets/purple-btn-spinner.svg';
import Loader from '../../components/Loader/Loader';

const EditPlayer = () => {
  const { id, playerId } = useParams();
  const { showProfileModal, baseURL } = useGlobalContext();
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`));
  const [playerDetails, setPlayerDetails] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);

  const fetchPlayerDetails = async () => {
    try {
      const { data } = await axios.get(
        `${baseURL}/leaderboard/${id}/player/${playerId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPlayerDetails(data.player);
      setPageLoading(false);
    } catch (error) {
      setLoading(false);
      setPageLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      points: ``,
      username: ``,
    },
    onSubmit() {
      updatePlayer();
    },
  });

  const updatePlayer = async () => {
    const { points, username } = formik.values;
    const formData = new FormData();
    if (points) {
      formData.append(`points`, points);
    }
    if (username) {
      formData.append(`username`, username);
    }

    try {
      setLoading(true);

      const { data } = await axios.patch(
        `${baseURL}/leaderboard/${id}/player/${playerDetails._id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      toast.success(data.msg);
      navigate(`/leaderboard`);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPlayerDetails();
    //eslint-disable-next-line
  }, [id]);

  const { points, username } = playerDetails;

  return (
    <main className='edit-player-page'>
      <Navbar
        category={`Management > Leaderboard >`}
        categoryLink={`/leaderboard`}
        currentPage={`Update Player`}
      />
      <Sidebar activePage={5} />

      <div className={`main-content ${showProfileModal ? `back` : null}`}>
        <div className='title-btn'>
          <FaChevronLeft onClick={() => navigate(`/leaderboard`)} />
          <h2 className='title'>Update Player</h2>
        </div>

        {pageLoading ? (
          <div className='loader-container'>
            <Loader />
          </div>
        ) : (
          <>
            <div className='player-details'>
              <h3 className='title'>Tip Details</h3>

              <form action='' onSubmit={formik.handleSubmit}>
                {/* USERNAME */}
                <div className='form-control'>
                  <label
                    htmlFor='username'
                    className={
                      formik.errors.username && formik.touched.username
                        ? `error`
                        : ``
                    }
                  >
                    {formik.errors.username && formik.touched.username
                      ? formik.errors.username
                      : `Username`}{' '}
                    <span>*</span>
                  </label>
                  <input
                    type='text'
                    id='username'
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name='username'
                    placeholder={username}
                  />
                </div>

                {/* USERNAME */}
                <div className='form-control'>
                  <label
                    htmlFor='points'
                    className={
                      formik.errors.points && formik.touched.points
                        ? `error`
                        : ``
                    }
                  >
                    {formik.errors.points && formik.touched.points
                      ? formik.errors.points
                      : `Points`}{' '}
                    <span>*</span>
                  </label>
                  <input
                    type='text'
                    id='points'
                    value={formik.values.points}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name='points'
                    placeholder={points}
                  />
                </div>

                {/* SUBMIT BUTTON */}
                <button type='submit' className='primary'>
                  Update {loading ? <img src={spinner} alt='' /> : null}
                </button>
              </form>
            </div>
          </>
        )}
      </div>
    </main>
  );
};

export default EditPlayer;
