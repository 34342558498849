import { useState } from 'react';
import { useGlobalContext } from '../../context/Context';
import { FaTimes } from 'react-icons/fa';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import spinner from '../../assets/white-spinner.svg';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { CKEditor } from 'ckeditor4-react';

// MODAL FOR WHEN ADMIN WANTS TO UPDATE SOCIAL MEDIA LINK
export const UpdateSocialMediaLink = ({ linkId, refreshFunc }) => {
  const { setUpdateSocialMedialLink, baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`))
    ? JSON.parse(sessionStorage.getItem(`adminData`))
    : ``;

  const formik = useFormik({
    initialValues: {
      link: ``,
      noOfFollowers: ``,
    },
    validationSchema: Yup.object({
      link: Yup.string().required(`Link is required`),
      noOfFollowers: Yup.string().required(`No of Followers is required`),
    }),
    onSubmit() {
      updateLink();
    },
  });

  const updateLink = async () => {
    try {
      setLoading(true);
      // eslint-disable-next-line
      const response = await axios.patch(
        `${baseURL}/social-media-links/${linkId}`,
        formik.values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      toast.success(`Link Updated`);
      setUpdateSocialMedialLink(false);
      refreshFunc();
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.msg);
    }
  };

  return (
    <div className='modal-container'>
      <div className='modal add-free-in-play'>
        {/* CLOSE MODAL BTN */}
        <div className='close-btn'>
          <FaTimes
            onClick={() => {
              setUpdateSocialMedialLink(false);
            }}
          />
        </div>

        <form action='' onSubmit={formik.handleSubmit}>
          <div className='form-control'>
            <label
              htmlFor='link'
              className={
                formik.errors.link && formik.touched.link ? `error` : ``
              }
            >
              {formik.errors.link && formik.touched.link
                ? formik.errors.link
                : `New Link`}
            </label>
            <input
              type='text'
              id='link'
              name='link'
              value={formik.values.link}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          <div className='form-control'>
            <label
              htmlFor='noOfFollowers'
              className={
                formik.errors.noOfFollowers && formik.touched.noOfFollowers
                  ? `error`
                  : ``
              }
            >
              {formik.errors.noOfFollowers && formik.touched.noOfFollowers
                ? formik.errors.noOfFollowers
                : `No. of Followers`}
            </label>
            <input
              type='text'
              id='noOfFollowers'
              name='noOfFollowers'
              value={formik.values.noOfFollowers}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          <button className='secondary' type='submit'>
            Update Link {loading && <img src={spinner} alt='' />}
          </button>
        </form>
      </div>
    </div>
  );
};

// MODAL FOR WHEN ADMIN WANTS TO ADD A CLUB
export const AddClub = ({ dismissFunc }) => {
  const { baseURL } = useGlobalContext();
  const [clubLogo, setClubLogo] = useState(``);
  const [loading, setLoading] = useState(false);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`))
    ? JSON.parse(sessionStorage.getItem(`adminData`))
    : ``;

  const formik = useFormik({
    initialValues: {
      clubName: ``,
    },
    onSubmit() {
      addClub();
    },
  });

  const formData = new FormData();
  const { clubName } = formik.values;
  formData.append(`clubLogo`, clubLogo);
  formData.append(`clubName`, clubName);

  const addClub = async () => {
    try {
      setLoading(true);
      await axios.post(`${baseURL}/clubs`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      toast.success(`Club created`);
      dismissFunc(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.msg);
    }
  };

  return (
    <div className='modal-container'>
      <div className='modal update-ad'>
        {/* CLOSE MODAL BTN */}
        <div className='close-btn'>
          <FaTimes
            onClick={() => {
              dismissFunc(false);
            }}
          />
        </div>

        <form action='' onSubmit={formik.handleSubmit}>
          {/* CLUB LOGO */}
          <div className='form-control'>
            <label htmlFor='adImage'>Club Logo</label>
            <input
              type='file'
              accept='image/*'
              id='adImage'
              name='adImage'
              onChange={(e) => setClubLogo(e.target.files[0])}
              required
            />
          </div>

          {/* CLUB NAME */}
          <div className='form-control'>
            <label htmlFor='clubName'>Club Name</label>
            <input
              type='text'
              id='clubName'
              name='clubName'
              value={formik.values.clubName}
              onChange={formik.handleChange}
              required
            />
          </div>

          <button className='secondary' type='submit'>
            Add Club {loading && <img src={spinner} alt='' />}
          </button>
        </form>
      </div>
    </div>
  );
};

// MODAL FOR WHEN ADMIN WANTS TO ADD A CLUB
export const AddCompetition = ({ dismissFunc }) => {
  const { baseURL } = useGlobalContext();
  const [competitionLogo, setCompetitionLogo] = useState(``);
  const [loading, setLoading] = useState(false);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`))
    ? JSON.parse(sessionStorage.getItem(`adminData`))
    : ``;

  const formik = useFormik({
    initialValues: {
      competitionName: ``,
    },
    onSubmit() {
      addClub();
    },
  });

  const formData = new FormData();
  const { competitionName } = formik.values;
  formData.append(`competitionLogo`, competitionLogo);
  formData.append(`competitionName`, competitionName);

  const addClub = async () => {
    try {
      setLoading(true);
      await axios.post(`${baseURL}/competitions`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      toast.success(`Club created`);
      dismissFunc(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.msg);
    }
  };

  return (
    <div className='modal-container'>
      <div className='modal update-ad'>
        {/* CLOSE MODAL BTN */}
        <div className='close-btn'>
          <FaTimes
            onClick={() => {
              dismissFunc(false);
            }}
          />
        </div>

        <form action='' onSubmit={formik.handleSubmit}>
          {/* CLUB LOGO */}
          <div className='form-control'>
            <label htmlFor='adImage'>Competition Logo</label>
            <input
              type='file'
              accept='image/*'
              id='adImage'
              name='adImage'
              onChange={(e) => setCompetitionLogo(e.target.files[0])}
              required
            />
          </div>

          {/* CLUB NAME */}
          <div className='form-control'>
            <label htmlFor='competitionName'>Competition Name</label>
            <input
              type='text'
              id='competitionName'
              name='competitionName'
              value={formik.values.competitionName}
              onChange={formik.handleChange}
              required
            />
          </div>

          <button className='secondary' type='submit'>
            Add Club {loading && <img src={spinner} alt='' />}
          </button>
        </form>
      </div>
    </div>
  );
};

// MODAL FOR WHEN ADMIN WANTS TO DELETE ALL VIP TIPS
export const DeleteAllVipTipsModal = () => {
  const { setDeleteVipTips, baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`))
    ? JSON.parse(sessionStorage.getItem(`adminData`))
    : ``;

  const deleteTips = async () => {
    try {
      setLoading(true);
      // eslint-disable-next-line
      const response = await axios.delete(
        `${baseURL}/vip-tickets/delete-tickets`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      toast.success(`Tickets Deleted`);
      setDeleteVipTips(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.msg);
    }
  };

  return (
    <div className='modal-container' id='delete-container'>
      <div className='modal delete-all'>
        <p>Are you sure you want to delete all VIP Tips?</p>

        <div className='btns'>
          <button className='grey' onClick={() => setDeleteVipTips(false)}>
            Cancel
          </button>
          <button className='red' onClick={deleteTips}>
            Delete {loading && <img src={spinner} alt='' />}
          </button>
        </div>
      </div>
    </div>
  );
};

// MODAL FOR WHEN ADMIN WANTS TO DELETE ALL BLOG POSTS
export const DeleteAllBlogPosts = () => {
  const { setDeleteBlogPosts, baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`))
    ? JSON.parse(sessionStorage.getItem(`adminData`))
    : ``;

  const deleteBlogPosts = async () => {
    try {
      setLoading(true);
      await axios.delete(`${baseURL}/news/delete-all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      toast.success(`Blog POsts Deleted`);
      setDeleteBlogPosts(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.msg);
    }
  };

  return (
    <div className='modal-container' id='delete-container'>
      <div className='modal delete-all'>
        <p>Are you sure you want to delete all Blog Posts?</p>

        <div className='btns'>
          <button className='grey' onClick={() => setDeleteBlogPosts(false)}>
            Cancel
          </button>
          <button className='red' onClick={deleteBlogPosts}>
            Delete {loading && <img src={spinner} alt='' />}
          </button>
        </div>
      </div>
    </div>
  );
};

// MODAL FOR WHEN ADMIN WANTS TO UPDATE BLOG POST
export const UpdateBlogPost = ({ postDetails }) => {
  const { setUpdateBlogPost, baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`));
  const [image, setImage] = useState(``);
  const [postContent, setPostContent] = useState(``);

  const formik = useFormik({
    initialValues: {
      title: postDetails.title,
      sportCategory: postDetails.sportCategory,
    },
    onSubmit() {
      createMatchOfTheDay();
    },
  });

  const createMatchOfTheDay = async () => {
    const { title, sportCategory } = formik.values;
    const formData = new FormData();

    if (title) {
      formData.append(`title`, title);
    }
    if (sportCategory) {
      formData.append(`sportCategory`, sportCategory);
    }
    if (postContent) {
      formData.append(`content`, postContent);
    }
    if (image) {
      formData.append(`image`, image);
    }

    try {
      setLoading(true);
      // eslint-disable-next-line
      const response = await axios.patch(
        `${baseURL}/news/${postDetails._id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      toast.success(`Post Updated`);
      setUpdateBlogPost(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div className='modal-container'>
      <div className='modal update-blog-post'>
        {/* CLOSE MODAL BTN */}
        <div className='close-btn'>
          <FaTimes
            onClick={() => {
              setUpdateBlogPost(false);
            }}
          />
        </div>

        <form action='' onSubmit={formik.handleSubmit}>
          {/* NEW IMAGE */}
          <div className='form-control'>
            <label htmlFor='image'>New Image</label>
            <input
              type='file'
              accept='image/*'
              id='image'
              name='image'
              onChange={(e) => setImage(e.target.files[0])}
            />
          </div>

          {/* TITLE */}
          <div className='form-control'>
            <label htmlFor='title'>Title</label>
            <input
              type='text'
              id='title'
              name='title'
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          {/* SPORT CATEGORY */}
          <div className='form-control'>
            <label htmlFor='sportCategory'>Sport Category</label>
            <input
              type='text'
              id='sportCategory'
              name='sportCategory'
              value={formik.values.sportCategory}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          {/* CONTENT */}
          <div className='form-control'>
            <label htmlFor='away-team'>Post Details</label>
            <CKEditor
              data={postDetails.content}
              onChange={(event) => setPostContent(event.editor.getData())}
            />
          </div>

          <button className='secondary' type='submit'>
            Update {loading && <img src={spinner} alt='' />}
          </button>
        </form>
      </div>
    </div>
  );
};

// MODAL FOR WHEN ADMIN WANTS TO DELETE A CUSTOMER
export const DeleteCustomer = ({ userId, refreshFunc }) => {
  const { setDeleteCustomer, baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`))
    ? JSON.parse(sessionStorage.getItem(`adminData`))
    : ``;

  const deleteCustomer = async () => {
    try {
      setLoading(true);
      // eslint-disable-next-line
      const response = await axios.delete(`${baseURL}/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      toast.success(`Customer Deleted`);
      setDeleteCustomer(false);
      navigate(`/customers`);
      refreshFunc();
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.msg);
    }
  };

  return (
    <div className='modal-container' id='delete-container'>
      <div className='modal delete-all'>
        <p>Are you sure you want to delete this customer?</p>

        <div className='btns'>
          <button className='grey' onClick={() => setDeleteCustomer(false)}>
            Cancel
          </button>
          <button className='red' onClick={deleteCustomer}>
            Delete {loading && <img src={spinner} alt='' />}
          </button>
        </div>
      </div>
    </div>
  );
};

// MODAL FOR WHEN ADMIN WANTS TO DELETE ALL CLUBS
export const DeleteClubs = ({ dismissFunc }) => {
  const { baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`))
    ? JSON.parse(sessionStorage.getItem(`adminData`))
    : ``;

  const deleteClubs = async () => {
    try {
      setLoading(true);
      const { data } = await axios.delete(`${baseURL}/clubs/delete-all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      toast.success(data.msg);
      dismissFunc(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.response);
    }
  };

  return (
    <div className='modal-container' id='delete-container'>
      <div className='modal delete-all'>
        <p>Are you sure you want delete all the clubs?</p>

        <div className='btns'>
          <button className='grey' onClick={() => dismissFunc(false)}>
            Cancel
          </button>
          <button className='red' onClick={deleteClubs}>
            Delete {loading && <img src={spinner} alt='' />}
          </button>
        </div>
      </div>
    </div>
  );
};

// MODAL FOR WHEN ADMIN WANTS TO DELETE ALL COMPETITIONS
export const DeleteCompetitions = ({ dismissFunc }) => {
  const { baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`))
    ? JSON.parse(sessionStorage.getItem(`adminData`))
    : ``;

  const deleteClubs = async () => {
    try {
      setLoading(true);
      const { data } = await axios.delete(
        `${baseURL}/competitions/delete-all`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      toast.success(data.msg);
      dismissFunc(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.response);
    }
  };

  return (
    <div className='modal-container' id='delete-container'>
      <div className='modal delete-all'>
        <p>Are you sure you want delete all the competitions?</p>

        <div className='btns'>
          <button className='grey' onClick={() => dismissFunc(false)}>
            Cancel
          </button>
          <button className='red' onClick={deleteClubs}>
            Delete {loading && <img src={spinner} alt='' />}
          </button>
        </div>
      </div>
    </div>
  );
};

// MODAL FOR WHEN ADMIN WANTS TO CREATE AN AFFILIATE
export const CreateAffiliate = ({ dismissFunc, refreshFunc }) => {
  const { baseURL } = useGlobalContext();
  const [image, setImage] = useState(``);
  const [loading, setLoading] = useState(false);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`))
    ? JSON.parse(sessionStorage.getItem(`adminData`))
    : ``;

  const formik = useFormik({
    initialValues: {
      link: ``,
      caption: ``,
    },
    onSubmit() {
      addClub();
    },
  });

  const formData = new FormData();
  const { caption, link } = formik.values;
  formData.append(`image`, image);
  formData.append(`link`, link);
  formData.append(`caption`, caption);

  const addClub = async () => {
    try {
      setLoading(true);
      await axios.post(`${baseURL}/affiliates`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      toast.success(`Affiliate Created Successfully`);
      dismissFunc(false);
      refreshFunc();
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.msg);
    }
  };

  return (
    <div className='modal-container'>
      <div className='modal update-ad'>
        {/* CLOSE MODAL BTN */}
        <div className='close-btn'>
          <FaTimes
            onClick={() => {
              dismissFunc(false);
            }}
          />
        </div>

        <form action='' onSubmit={formik.handleSubmit}>
          {/* AFFILIATE IMAGE */}
          <div className='form-control'>
            <label htmlFor='image'>Affiliate Image</label>
            <input
              type='file'
              accept='image/*'
              id='image'
              name='image'
              onChange={(e) => setImage(e.target.files[0])}
              required
            />
          </div>

          {/* AFFILIATE LINK */}
          <div className='form-control'>
            <label htmlFor='link'>Affiliate Link</label>
            <input
              type='text'
              id='link'
              name='link'
              value={formik.values.link}
              onChange={formik.handleChange}
              required
            />
          </div>

          {/* AFFILIATE CAPTION */}
          <div className='form-control'>
            <label htmlFor='captionbName'>Affiliate Caption</label>
            <input
              type='text'
              id='caption'
              name='caption'
              value={formik.values.caption}
              onChange={formik.handleChange}
              required
            />
          </div>

          <button className='secondary' type='submit'>
            Create Affiliate {loading && <img src={spinner} alt='' />}
          </button>
        </form>
      </div>
    </div>
  );
};

// MODAL FOR WHEN ADMIN WANTS TO UPDATE AFFILIATE
export const UpdateAffiliate = ({ affiliateId, dismissFunc }) => {
  const { baseURL } = useGlobalContext();
  const [image, setImage] = useState(``);
  const [loading, setLoading] = useState(false);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`))
    ? JSON.parse(sessionStorage.getItem(`adminData`))
    : ``;

  const formik = useFormik({
    initialValues: {
      link: ``,
      caption: ``,
    },
    onSubmit() {
      updateAd();
    },
  });

  const formData = new FormData();
  const { caption, link } = formik.values;
  if (image) {
    formData.append(`image`, image);
  }
  if (link) {
    formData.append(`link`, link);
  }
  if (caption) {
    formData.append(`caption`, caption);
  }

  const updateAd = async () => {
    try {
      setLoading(true);
      await axios.patch(`${baseURL}/affiliates/${affiliateId}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      toast.success(`Affiliate Updated`);
      dismissFunc(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div className='modal-container'>
      <div className='modal update-ad'>
        {/* CLOSE MODAL BTN */}
        <div className='close-btn'>
          <FaTimes
            onClick={() => {
              dismissFunc(false);
            }}
          />
        </div>

        <form action='' onSubmit={formik.handleSubmit}>
          {/* NEW AFFILIATE IMAGE */}
          <div className='form-control'>
            <label htmlFor='image'>New Affiliate Image</label>
            <input
              type='file'
              accept='image/*'
              id='image'
              name='image'
              onChange={(e) => setImage(e.target.files[0])}
            />
          </div>

          {/* NEW AFFILIATE LINK */}
          <div className='form-control'>
            <label htmlFor='link'>New Affiliate Link</label>
            <input
              type='text'
              id='link'
              name='link'
              value={formik.values.link}
              onChange={formik.handleChange}
            />
          </div>

          {/* NEW AFFILIATE CAPTION */}
          <div className='form-control'>
            <label htmlFor='caption'>New Affiliate Caption</label>
            <input
              type='text'
              id='caption'
              name='caption'
              value={formik.values.caption}
              onChange={formik.handleChange}
            />
          </div>

          <button className='secondary' type='submit'>
            Update Affiliate {loading && <img src={spinner} alt='' />}
          </button>
        </form>
      </div>
    </div>
  );
};

// MODAL FOR WHEN ADMIN WANTS TO DELETE ALL AFFILIATES
export const DeleteAffiliates = ({ dismissFunc, refreshFunc }) => {
  const { baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`))
    ? JSON.parse(sessionStorage.getItem(`adminData`))
    : ``;

  const deleteAffiliates = async () => {
    try {
      setLoading(true);
      const { data } = await axios.delete(`${baseURL}/affiliates/delete-all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      toast.success(data.msg);
      dismissFunc(false);
      refreshFunc();
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.msg);
    }
  };

  return (
    <div className='modal-container' id='delete-container'>
      <div className='modal delete-all'>
        <p>Are you sure you want delete all the Affiliates?</p>

        <div className='btns'>
          <button className='grey' onClick={() => dismissFunc(false)}>
            Cancel
          </button>
          <button className='red' onClick={deleteAffiliates}>
            Delete {loading && <img src={spinner} alt='' />}
          </button>
        </div>
      </div>
    </div>
  );
};

// MODAL FOR WHEN ADMIN WANTS TO CREATE A POP-UP
export const CreatePopup = ({ dismissFunc, refreshFunc }) => {
  const { baseURL } = useGlobalContext();
  const [image, setImage] = useState(``);
  const [loading, setLoading] = useState(false);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`))
    ? JSON.parse(sessionStorage.getItem(`adminData`))
    : ``;

  const formik = useFormik({
    initialValues: {
      link: ``,
    },
    onSubmit() {
      createPopup();
    },
  });

  const formData = new FormData();
  const { link } = formik.values;
  formData.append(`image`, image);
  formData.append(`link`, link);

  const createPopup = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(`${baseURL}/popups`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      toast.success(`Pop-up Created Successfully`);
      dismissFunc(false);
      console.log(data);
      refreshFunc();
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.msg);
    }
  };

  return (
    <div className='modal-container'>
      <div className='modal update-ad'>
        {/* CLOSE MODAL BTN */}
        <div className='close-btn'>
          <FaTimes
            onClick={() => {
              dismissFunc(false);
            }}
          />
        </div>

        <form action='' onSubmit={formik.handleSubmit}>
          {/* POP-UP IMAGE */}
          <div className='form-control'>
            <label htmlFor='image'>Pop-up Image</label>
            <input
              type='file'
              accept='image/*'
              id='image'
              name='image'
              onChange={(e) => setImage(e.target.files[0])}
              required
            />
          </div>

          {/* POP-UP LINK */}
          <div className='form-control'>
            <label htmlFor='link'>Pop-up Link</label>
            <input
              type='text'
              id='link'
              name='link'
              value={formik.values.link}
              onChange={formik.handleChange}
              required
            />
          </div>

          <button className='secondary' type='submit'>
            Create Pop-up {loading && <img src={spinner} alt='' />}
          </button>
        </form>
      </div>
    </div>
  );
};

// MODAL FOR WHEN ADMIN WANTS TO UPDATE A POP-UP
export const UpdatePopup = ({ popupId, dismissFunc }) => {
  const { baseURL } = useGlobalContext();
  const [image, setImage] = useState(``);
  const [loading, setLoading] = useState(false);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`))
    ? JSON.parse(sessionStorage.getItem(`adminData`))
    : ``;

  const formik = useFormik({
    initialValues: {
      link: ``,
    },
    onSubmit() {
      updatePopup();
    },
  });

  const formData = new FormData();
  const { link } = formik.values;
  if (image) {
    formData.append(`image`, image);
  }
  if (link) {
    formData.append(`link`, link);
  }

  const updatePopup = async () => {
    try {
      setLoading(true);
      await axios.patch(`${baseURL}/popups/${popupId}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      toast.success(`Pop-up Updated`);
      dismissFunc(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div className='modal-container'>
      <div className='modal update-ad'>
        {/* CLOSE MODAL BTN */}
        <div className='close-btn'>
          <FaTimes
            onClick={() => {
              dismissFunc(false);
            }}
          />
        </div>

        <form action='' onSubmit={formik.handleSubmit}>
          {/* NEW POP-UP IMAGE */}
          <div className='form-control'>
            <label htmlFor='image'>New Pop-up Image</label>
            <input
              type='file'
              accept='image/*'
              id='image'
              name='image'
              onChange={(e) => setImage(e.target.files[0])}
            />
          </div>

          {/* NEW POP-UP LINK */}
          <div className='form-control'>
            <label htmlFor='link'>New Pop-up Link</label>
            <input
              type='text'
              id='link'
              name='link'
              value={formik.values.link}
              onChange={formik.handleChange}
            />
          </div>

          <button className='secondary' type='submit'>
            Update Pop-up {loading && <img src={spinner} alt='' />}
          </button>
        </form>
      </div>
    </div>
  );
};

// MODAL FOR WHEN ADMIN WANTS TO DELETE ALL POP-UPS
export const DeletePopups = ({ dismissFunc, refreshFunc }) => {
  const { baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`))
    ? JSON.parse(sessionStorage.getItem(`adminData`))
    : ``;

  const deletePopups = async () => {
    try {
      setLoading(true);
      const { data } = await axios.delete(`${baseURL}/popups/delete-all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      toast.success(data.msg);
      dismissFunc(false);
      refreshFunc();
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.msg);
    }
  };

  return (
    <div className='modal-container' id='delete-container'>
      <div className='modal delete-all'>
        <p>Are you sure you want delete all pop-ups?</p>

        <div className='btns'>
          <button className='grey' onClick={() => dismissFunc(false)}>
            Cancel
          </button>
          <button className='red' onClick={deletePopups}>
            Delete {loading && <img src={spinner} alt='' />}
          </button>
        </div>
      </div>
    </div>
  );
};

// MODAL FOR WHEN ADMIN WANTS TO DELETE ALL FREE ACCUMULATOR TIPS
export const DeleteAllFreeAccaTiipsModal = ({ dismissFunc, refreshFunc }) => {
  const { baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`))
    ? JSON.parse(sessionStorage.getItem(`adminData`))
    : ``;

  const deleteTips = async () => {
    try {
      setLoading(true);
      const { data } = await axios.delete(
        `${baseURL}/free-accumulator-tips/delete-all`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      toast.success(data.msg);
      dismissFunc(false);
      refreshFunc();
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.msg);
    }
  };

  return (
    <div className='modal-container' id='delete-container'>
      <div className='modal delete-all'>
        <p>Are you sure you want to delete all Free Accumulator Tips?</p>
        <div className='btns'>
          <button className='grey' onClick={() => dismissFunc(false)}>
            Cancel
          </button>
          <button className='red' onClick={deleteTips}>
            Delete {loading && <img src={spinner} alt='' />}
          </button>
        </div>
      </div>
    </div>
  );
};

// MODAL FOR WHEN ADMIN WANTS TO DELETE ALL CUSTOMERS
export const DeleteAllCustomersModal = ({ dismissFunc, refreshFunc }) => {
  const { baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`))
    ? JSON.parse(sessionStorage.getItem(`adminData`))
    : ``;

  const deleteCustomers = async () => {
    try {
      setLoading(true);
      const { data } = await axios.delete(`${baseURL}/users/delete-all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      toast.success(data.msg);
      dismissFunc(false);
      refreshFunc();
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.msg);
    }
  };

  return (
    <div className='modal-container' id='delete-container'>
      <div className='modal delete-all'>
        <p>Are you sure you want to delete all Customers?</p>
        <div className='btns'>
          <button className='grey' onClick={() => dismissFunc(false)}>
            Cancel
          </button>
          <button className='red' onClick={deleteCustomers}>
            Delete {loading && <img src={spinner} alt='' />}
          </button>
        </div>
      </div>
    </div>
  );
};

// MODAL FOR WHEN ADMIN WANTS TO CREATE A GIF
export const CreateGif = ({ dismissFunc, refreshFunc }) => {
  const { baseURL } = useGlobalContext();
  const [mobileImage, setMobileImage] = useState(``);
  const [desktopImage, setDesktopImage] = useState(``);
  const [loading, setLoading] = useState(false);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`))
    ? JSON.parse(sessionStorage.getItem(`adminData`))
    : ``;

  const formik = useFormik({
    initialValues: {
      link: ``,
    },
    onSubmit() {
      createGif();
    },
  });

  const formData = new FormData();
  const { link } = formik.values;
  formData.append(`mobileImage`, mobileImage);
  formData.append(`desktopImage`, desktopImage);
  formData.append(`link`, link);

  const createGif = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(`${baseURL}/gifs`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      toast.success(`GIF Created Successfully`);
      dismissFunc(false);
      console.log(data);
      refreshFunc();
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.msg);
    }
  };

  return (
    <div className='modal-container'>
      <div className='modal update-ad'>
        {/* CLOSE MODAL BTN */}
        <div className='close-btn'>
          <FaTimes
            onClick={() => {
              dismissFunc(false);
            }}
          />
        </div>

        <form action='' onSubmit={formik.handleSubmit}>
          {/* MOBILE IMAGE */}
          <div className='form-control'>
            <label htmlFor='image'>Mobile Image</label>
            <input
              type='file'
              accept='image/*'
              id='image'
              name='image'
              onChange={(e) => setMobileImage(e.target.files[0])}
              required
            />
          </div>

          {/* DESKTOP IMAGE */}
          <div className='form-control'>
            <label htmlFor='image'>Desktop Image</label>
            <input
              type='file'
              accept='image/*'
              id='image'
              name='image'
              onChange={(e) => setDesktopImage(e.target.files[0])}
              required
            />
          </div>

          {/* LINK */}
          <div className='form-control'>
            <label htmlFor='link'>GIF Link</label>
            <input
              type='text'
              id='link'
              name='link'
              value={formik.values.link}
              onChange={formik.handleChange}
              required
            />
          </div>

          <button className='secondary' type='submit'>
            Create GIF {loading && <img src={spinner} alt='' />}
          </button>
        </form>
      </div>
    </div>
  );
};

// MODAL FOR WHEN ADMIN WANTS TO DELETE ALL GIFS
export const DeleteGifs = ({ dismissFunc, refreshFunc }) => {
  const { baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`))
    ? JSON.parse(sessionStorage.getItem(`adminData`))
    : ``;

  const deleteGifs = async () => {
    try {
      setLoading(true);
      const { data } = await axios.delete(`${baseURL}/gifs/delete-all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      toast.success(data.msg);
      dismissFunc(false);
      refreshFunc();
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.msg);
    }
  };

  return (
    <div className='modal-container' id='delete-container'>
      <div className='modal delete-all'>
        <p>Are you sure you want delete all GIFs?</p>

        <div className='btns'>
          <button className='grey' onClick={() => dismissFunc(false)}>
            Cancel
          </button>
          <button className='red' onClick={deleteGifs}>
            Delete {loading && <img src={spinner} alt='' />}
          </button>
        </div>
      </div>
    </div>
  );
};

// MODAL FOR WHEN ADMIN WANTS TO CREATE AN AD
export const CreateAd = ({ dismissFunc, refreshFunc }) => {
  const { baseURL } = useGlobalContext();
  const [image, setImage] = useState(``);
  const [loading, setLoading] = useState(false);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`))
    ? JSON.parse(sessionStorage.getItem(`adminData`))
    : ``;

  const formik = useFormik({
    initialValues: {
      link: ``,
    },
    onSubmit() {
      createPopup();
    },
  });

  const formData = new FormData();
  const { link } = formik.values;
  formData.append(`image`, image);
  formData.append(`adLink`, link);

  const createPopup = async () => {
    try {
      setLoading(true);
      await axios.post(`${baseURL}/ads`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      toast.success(`Ad Created Successfully`);
      dismissFunc(false);
      refreshFunc();
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.msg);
    }
  };

  return (
    <div className='modal-container'>
      <div className='modal update-ad'>
        {/* CLOSE MODAL BTN */}
        <div className='close-btn'>
          <FaTimes
            onClick={() => {
              dismissFunc(false);
            }}
          />
        </div>

        <form action='' onSubmit={formik.handleSubmit}>
          {/* AD IMAGE */}
          <div className='form-control'>
            <label htmlFor='image'>Ad Image</label>
            <input
              type='file'
              accept='image/*'
              id='image'
              name='image'
              onChange={(e) => setImage(e.target.files[0])}
              required
            />
          </div>

          {/* AD LINK */}
          <div className='form-control'>
            <label htmlFor='link'>Ad Link</label>
            <input
              type='text'
              id='link'
              name='link'
              value={formik.values.link}
              onChange={formik.handleChange}
              required
            />
          </div>

          <button className='secondary' type='submit'>
            Create Ad {loading && <img src={spinner} alt='' />}
          </button>
        </form>
      </div>
    </div>
  );
};

// MODAL FOR WHEN ADMIN WANTS TO UPDATE ADS
export const UpdateAd = ({ adId, dismissFunc }) => {
  const { baseURL } = useGlobalContext();
  const [adImage, setAdImage] = useState(``);
  const [loading, setLoading] = useState(false);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`))
    ? JSON.parse(sessionStorage.getItem(`adminData`))
    : ``;

  const formik = useFormik({
    initialValues: {
      adLink: ``,
    },
    onSubmit() {
      updateAd();
    },
  });

  const formData = new FormData();
  const { adLink } = formik.values;
  if (adImage) {
    formData.append(`image`, adImage);
  }
  if (adLink) {
    formData.append(`adLink`, formik.values.adLink);
  }

  const updateAd = async () => {
    try {
      setLoading(true);
      await axios.patch(`${baseURL}/ads/${adId}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      toast.success(`Ad Updated`);
      dismissFunc(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div className='modal-container'>
      <div className='modal update-ad'>
        {/* CLOSE MODAL BTN */}
        <div className='close-btn'>
          <FaTimes
            onClick={() => {
              dismissFunc(false);
            }}
          />
        </div>

        <form action='' onSubmit={formik.handleSubmit}>
          {/* NEW AD IMAGE */}
          <div className='form-control'>
            <label htmlFor='adImage'>New Ad Image</label>
            <input
              type='file'
              accept='image/*'
              id='adImage'
              name='adImage'
              onChange={(e) => setAdImage(e.target.files[0])}
            />
          </div>

          {/* NEW AD LINK */}
          <div className='form-control'>
            <label htmlFor='adLink'>New Ad Link</label>
            <input
              type='text'
              id='adLink'
              name='adLink'
              value={formik.values.adLink}
              onChange={formik.handleChange}
            />
          </div>

          <button className='secondary' type='submit'>
            Update Ad {loading && <img src={spinner} alt='' />}
          </button>
        </form>
      </div>
    </div>
  );
};

// MODAL FOR WHEN ADMIN WANTS TO DELETE ALL ADS
export const DeleteAds = ({ dismissFunc, refreshFunc }) => {
  const { baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`))
    ? JSON.parse(sessionStorage.getItem(`adminData`))
    : ``;

  const deletePopups = async () => {
    try {
      setLoading(true);
      const { data } = await axios.delete(`${baseURL}/ads/delete-all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      toast.success(data.msg);
      dismissFunc(false);
      refreshFunc();
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.msg);
    }
  };

  return (
    <div className='modal-container' id='delete-container'>
      <div className='modal delete-all'>
        <p>Are you sure you want delete all Ads?</p>

        <div className='btns'>
          <button className='grey' onClick={() => dismissFunc(false)}>
            Cancel
          </button>
          <button className='red' onClick={deletePopups}>
            Delete {loading && <img src={spinner} alt='' />}
          </button>
        </div>
      </div>
    </div>
  );
};

// MODAL FOR WHEN ADMIN WANTS TO DELETE MATCH OF THE DAY
export const DeleteMatchOfTheDayModal = ({ dismissFunc, refreshFunc }) => {
  const { baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`))
    ? JSON.parse(sessionStorage.getItem(`adminData`))
    : ``;

  const deleteTips = async () => {
    try {
      setLoading(true);
      const { data } = await axios.delete(
        `${baseURL}/match-of-the-day/delete-all`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      toast.success(data.msg);
      dismissFunc(false);
      refreshFunc();
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.msg);
    }
  };

  return (
    <div className='modal-container' id='delete-container'>
      <div className='modal delete-all'>
        <p>Are you sure you want to delete Match of the day?</p>
        <div className='btns'>
          <button className='grey' onClick={() => dismissFunc(false)}>
            Cancel
          </button>
          <button className='red' onClick={deleteTips}>
            Delete {loading && <img src={spinner} alt='' />}
          </button>
        </div>
      </div>
    </div>
  );
};
