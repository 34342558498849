import React, { useEffect, useState } from 'react';
import { useGlobalContext } from '../../context/Context';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

// COMPONENTS
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';

// ASSETS
import spinner from '../../assets/purple-btn-spinner.svg';
import { toast } from 'react-toastify';
import { FaChevronLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const NewDailyTip = () => {
  const { showProfileModal, baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [logos, setLogos] = useState([]);
  const [competitions, setCompetitions] = useState([]);

  const { token } = JSON.parse(sessionStorage.getItem(`adminData`));
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      homeTeam: ``,
      homeTeamLogo: ``,
      awayTeam: ``,
      awayTeamLogo: ``,
      predictionOption: ``,
      prediction: ``,
      matchTime: ``,
      competitionLogo: ``,
      competitionName: ``,
    },
    validationSchema: Yup.object({
      homeTeam: Yup.string().required(`Please provide home team`),
      awayTeam: Yup.string().required(`Please provide away team`),
      matchTime: Yup.string().required(`Please provide match time`),
      homeTeamLogo: Yup.string().required(`Please provide home logo`),
      awayTeamLogo: Yup.string().required(`Please provide away logo`),
      competitionLogo: Yup.string().required(`Please provide competition logo`),
      competitionName: Yup.string().required(`Please provide competition name`),
      predictionOption: Yup.string().required(`Please provide option`),
    }),
    onSubmit() {
      createTip();
    },
  });

  const createTip = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(
        `${baseURL}/daily-tips`,
        formik.values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      toast.success(data.msg);
      navigate(`/daily-tips`);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.msg);
    }
  };

  const fetchLogos = async () => {
    try {
      const { data } = await axios.get(`${baseURL}/clubs`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLogos(data.clubs);
    } catch (error) {}
  };

  const fetchCompetitons = async () => {
    try {
      const { data } = await axios.get(`${baseURL}/competitions`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCompetitions(data.competitions);
    } catch (error) {}
  };

  useEffect(() => {
    fetchLogos();
    fetchCompetitons();
    // eslint-disable-next-line
  }, []);

  return (
    <main className='new-daily-tip-page'>
      <Navbar
        category={`Menu > Daily Tips >`}
        categoryLink={`/daily-tips`}
        currentPage={`New Daily Tip`}
      />
      <Sidebar activePage={1} />
      <div className={`main-content ${showProfileModal ? `back` : null}`}>
        <div className='title-btn'>
          <FaChevronLeft onClick={() => navigate(`/daily-tips`)} />
          <h2 className='title'>New Daily Tip</h2>
        </div>

        {/* TICKET DETAILS BOX */}
        <div className='ticket-details'>
          <h3 className='title'>Ticket Details</h3>

          <form action='' onSubmit={formik.handleSubmit}>
            {/* HOME TEAM */}
            <div className='form-control'>
              <label
                htmlFor='homeTeam'
                className={
                  formik.errors.homeTeam && formik.touched.homeTeam
                    ? `error`
                    : ``
                }
              >
                {formik.errors.homeTeam && formik.touched.homeTeam
                  ? formik.errors.homeTeam
                  : `Home Team`}{' '}
                <span>*</span>
              </label>
              <input
                type='text'
                id='homeTeam'
                value={formik.values.homeTeam}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name='homeTeam'
              />
            </div>

            {/* HOME TEAM LOGO */}
            <div className='form-control'>
              <label
                htmlFor='homeTeamLogo'
                className={
                  formik.errors.homeTeamLogo && formik.touched.homeTeamLogo
                    ? `error`
                    : ``
                }
              >
                {formik.errors.homeTeamLogo && formik.touched.homeTeamLogo
                  ? formik.errors.homeTeamLogo
                  : `Home Team Logo`}{' '}
                <span>*</span>
              </label>
              <select
                name='homeTeamLogo'
                id='homeTeamLogo'
                value={formik.values.homeTeamLogo}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option defaultChecked hidden>
                  Select Logo
                </option>
                {logos.map((logo) => {
                  const { _id, clubName, clubLogo } = logo;
                  return (
                    <option
                      value={`https://api.crspredictions.com${clubLogo}`}
                      key={_id}
                    >
                      {clubName} Logo
                    </option>
                  );
                })}
              </select>
            </div>

            {/* AWAY TEAM */}
            <div className='form-control'>
              <label
                htmlFor='awayTeam'
                className={
                  formik.errors.awayTeam && formik.touched.awayTeam
                    ? `error`
                    : ``
                }
              >
                {formik.errors.awayTeam && formik.touched.awayTeam
                  ? formik.errors.awayTeam
                  : `Away Team`}{' '}
                <span>*</span>
              </label>
              <input
                type='text'
                id='awayTeam'
                value={formik.values.awayTeam}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name='awayTeam'
              />
            </div>

            {/* AWAY TEAM LOGO */}
            <div className='form-control'>
              <label
                htmlFor='awayTeamLogo'
                className={
                  formik.errors.awayTeamLogo && formik.touched.awayTeamLogo
                    ? `error`
                    : ``
                }
              >
                {formik.errors.awayTeamLogo && formik.touched.awayTeamLogo
                  ? formik.errors.awayTeamLogo
                  : `Away Team Logo`}{' '}
                <span>*</span>
              </label>
              <select
                name='awayTeamLogo'
                id='awayTeamLogo'
                value={formik.values.awayTeamLogo}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option defaultChecked hidden>
                  Select Logo
                </option>
                {logos.map((logo) => {
                  const { _id, clubName, clubLogo } = logo;
                  return (
                    <option
                      value={`https://api.crspredictions.com${clubLogo}`}
                      key={_id}
                    >
                      {clubName} Logo
                    </option>
                  );
                })}
              </select>
            </div>

            {/* COMPETITION NAME */}
            <div className='form-control'>
              <label
                htmlFor='competitionName'
                className={
                  formik.errors.competitionName &&
                  formik.touched.competitionName
                    ? `error`
                    : ``
                }
              >
                {formik.errors.competitionName && formik.touched.competitionName
                  ? formik.errors.competitionName
                  : `Competition Name`}{' '}
                <span>*</span>
              </label>
              <input
                type='text'
                id='competitionName'
                value={formik.values.competitionName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name='competitionName'
              />
            </div>

            {/* COMPETITION LOGO */}
            <div className='form-control'>
              <label
                htmlFor='competitionLogo'
                className={
                  formik.errors.competitionLogo &&
                  formik.touched.competitionLogo
                    ? `error`
                    : ``
                }
              >
                {formik.errors.competitionLogo && formik.touched.competitionLogo
                  ? formik.errors.competitionLogo
                  : `Competition Logo`}{' '}
                <span>*</span>
              </label>
              <select
                name='competitionLogo'
                id='competitionLogo'
                value={formik.values.competitionLogo}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option defaultChecked hidden>
                  Select Logo
                </option>
                {competitions.map((logo) => {
                  const { _id, competitionName, competitionLogo } = logo;
                  return (
                    <option
                      value={`https://api.crspredictions.com${competitionLogo}`}
                      key={_id}
                    >
                      {competitionName} Logo
                    </option>
                  );
                })}
              </select>
            </div>

            {/* PREDICTION OPTION */}
            <div className='form-control'>
              <label htmlFor='image'>
                Prediction Option
                <span>*</span>
              </label>

              <select
                name='predictionOption'
                id='predictionOption'
                value={formik.values.predictionOption}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option defaultChecked hidden>
                  Select Prediction Option
                </option>
                <option value='over 1.5'>Over 1.5</option>
                <option value='over 2.5'>Over 2.5</option>
                <option value='over 3.5'>Over 3.5</option>
                <option value='under 2.5'>Under 2.5</option>
                <option value='under 3.5'>Under 3.5</option>
                <option value='ht/ft'>HT/FT</option>
                <option value='btts/gg'>BTTS/GG</option>
                <option value='corners'>Corners</option>
                <option value='players'>Players</option>
              </select>
            </div>

            {formik.values.predictionOption === `ht/ft` ||
            formik.values.predictionOption === `btts/gg` ||
            formik.values.predictionOption === `corners` ||
            formik.values.predictionOption === `players` ? (
              <>
                {/* PREDICTION */}
                <div className='form-control'>
                  <label
                    htmlFor='prediction'
                    className={
                      formik.errors.prediction && formik.touched.prediction
                        ? `error`
                        : ``
                    }
                  >
                    {formik.errors.prediction && formik.touched.prediction
                      ? formik.errors.prediction
                      : `Prediction`}{' '}
                    <span>*</span>
                  </label>
                  <input
                    type='text'
                    id='prediction'
                    value={formik.values.prediction}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name='prediction'
                  />
                </div>
              </>
            ) : null}

            {/* MATCH TIME */}
            <div className='form-control'>
              <label
                htmlFor='matchTime'
                className={
                  formik.errors.matchTime && formik.touched.matchTime
                    ? `error`
                    : ``
                }
              >
                {formik.errors.matchTime && formik.touched.matchTime
                  ? formik.errors.matchTime
                  : `Match Time`}{' '}
                <span>*</span>
              </label>
              <input
                name='matchTime'
                id='matchTime'
                type='datetime-local'
                value={formik.values.matchTime}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>

            {/* SUBMIT BUTTON */}
            <button type='submit' className='primary'>
              Create {loading ? <img src={spinner} alt='' /> : null}
            </button>
          </form>
        </div>
      </div>
    </main>
  );
};

export default NewDailyTip;
