import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';
import FunctionsBar from '../../components/Functions-Bar/FunctionsBar';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../context/Context';
import Loader from '../../components/Loader/Loader';
import axios from 'axios';
import { DeleteMatchOfTheDayModal } from '../../components/Modals/Modals';
import moment from 'moment';

const MatchOfTheDay = () => {
  const { showProfileModal, baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(true);
  const [matchOfTheDay, setMatchOfTheDay] = useState({});
  const [deleteMatch, setDeleteMatch] = useState(false);

  const { token } = JSON.parse(sessionStorage.getItem(`adminData`));
  const navigate = useNavigate();

  const getMatchOfTheDay = async () => {
    try {
      const { data } = await axios.get(`${baseURL}/match-of-the-day`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      setMatchOfTheDay(data.matches[0]);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMatchOfTheDay();
    // eslint-disable-next-line
  }, []);

  return (
    <main className='motd-page'>
      <Navbar category={`Menu >`} currentPage={`Match Of The Day`} />
      <Sidebar activePage={4} />

      <div className={`main-content ${showProfileModal ? `back` : null}`}>
        <FunctionsBar>
          <button
            className='primary'
            onClick={() => navigate(`/match-of-the-day/new-match`)}
          >
            Create Match
          </button>
          <button className='red' onClick={() => setDeleteMatch(true)}>
            Delete Match
          </button>
        </FunctionsBar>

        {loading ? (
          <Loader />
        ) : !loading && matchOfTheDay ? (
          <div className='match-details'>
            <h3 className='title'>Match Details</h3>

            {/* TEAMS */}
            <div className='form-control'>
              <label htmlFor=''>Teams</label>
              <h3>
                <img
                  src={`https://api.crspredictions.com${matchOfTheDay.homeTeamLogo}`}
                  alt={`${matchOfTheDay.homeTeamLogo} logo`}
                />
                {matchOfTheDay.homeTeam}
                {` `}
                <span>vs</span>
                {` `}
                {matchOfTheDay.awayTeam}
                <img
                  src={`https://api.crspredictions.com${matchOfTheDay.awayTeamLogo}`}
                  alt={`${matchOfTheDay.awayTeamLogo} logo`}
                />
              </h3>
            </div>

            {/* COMPETITION */}
            <div className='form-control'>
              <label htmlFor=''>Competition</label>
              <h3>
                {matchOfTheDay.competition}
                <img
                  src={matchOfTheDay.competitionLogo}
                  alt={`${matchOfTheDay.competitionLogo} logo`}
                />
              </h3>
            </div>

            {/* MATCH TIME */}
            <div className='form-control'>
              <label htmlFor=''>Match Time</label>
              <h3>{moment(matchOfTheDay.matchTime).format(`lll`)}</h3>
            </div>

            {/* INSTAGRAM LINK */}
            <div className='form-control'>
              <label htmlFor=''>Instagram Link</label>
              <h3 className='lower'>{matchOfTheDay.instagramLink}</h3>
            </div>

            {/* TWITTER LINK */}
            <div className='form-control'>
              <label htmlFor=''>Twitter Link</label>
              <h3 className='lower'>{matchOfTheDay.twitterLink}</h3>
            </div>
          </div>
        ) : (
          <div className='no-match'>
            <h2>No Match Yet</h2>
          </div>
        )}
      </div>
      {deleteMatch && (
        <DeleteMatchOfTheDayModal
          dismissFunc={setDeleteMatch}
          refreshFunc={getMatchOfTheDay}
        />
      )}
    </main>
  );
};

export default MatchOfTheDay;
