import axios from 'axios';
import React, { useContext, useState } from 'react';

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const baseURL = `https://api.crspredictions.com/api/v1`;
  // const baseURL = `http://localhost:5000/api/v1`;
  const [showNavbar, setShowNavbar] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [addMatch, setAddMatch] = useState(false);
  const [matches, setMatches] = useState([]);
  const [addMatchOfTheDay, setAddMatchOfTheDay] = useState(false);
  const [updateMatchOfTheDay, setUpdateMatchOfTheDay] = useState(false);
  const [addDailyMultipleMatch, setAddDailyMultipleMatch] = useState(false);
  const [addFreeInPlay, setAddFreeInPlay] = useState(false);
  const [updateSocialMedialLink, setUpdateSocialMedialLink] = useState(false);
  const [deleteVipTips, setDeleteVipTips] = useState(false);
  const [deleteBlogPosts, setDeleteBlogPosts] = useState(false);
  const [updateBlogPost, setUpdateBlogPost] = useState(false);
  const [deleteCustomer, setDeleteCustomer] = useState(false);
  const [updateCustomerVipStatus, setUpdateCustomerVipStatus] = useState(false);

  // RESET PAGE
  const resetPage = () => {
    window.scrollTo({ top: 0, left: 0 });
  };

  // WAKE SERVER
  const wakeServer = async () => {
    try {
      await axios.get(`https://api.crspredictions.com/`);
    } catch (error) {}
  };

  // SHOW AND HIDE ALERT
  const showHideAlert = () => {
    setTimeout(() => {
      setShowAlert(false);
    }, 2000);
  };

  // SHOW PASSWORD
  const revealPassword = (id) => {
    const passwordInput = document.querySelector(`#${id}`);
    if (passwordInput.type === `password`) {
      passwordInput.type = `text`;
    } else {
      passwordInput.type = `password`;
    }
  };

  return (
    <AppContext.Provider
      value={{
        resetPage,
        showNavbar,
        showProfileModal,
        setShowProfileModal,
        setShowNavbar,
        baseURL,
        showAlert,
        setShowAlert,
        showHideAlert,
        wakeServer,
        addMatch,
        setAddMatch,
        matches,
        setMatches,
        addMatchOfTheDay,
        setAddMatchOfTheDay,
        updateMatchOfTheDay,
        setUpdateMatchOfTheDay,
        addDailyMultipleMatch,
        setAddDailyMultipleMatch,
        addFreeInPlay,
        setAddFreeInPlay,
        updateSocialMedialLink,
        setUpdateSocialMedialLink,
        deleteVipTips,
        setDeleteVipTips,
        deleteBlogPosts,
        updateBlogPost,
        setUpdateBlogPost,
        setDeleteBlogPosts,
        deleteCustomer,
        setDeleteCustomer,
        updateCustomerVipStatus,
        setUpdateCustomerVipStatus,
        revealPassword,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useGlobalContext = () => {
  return useContext(AppContext);
};

export { useGlobalContext, AppProvider };
