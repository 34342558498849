import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../context/Context';
import { FaChevronLeft } from 'react-icons/fa';
import { useState } from 'react';
import { RiDeleteBin2Fill } from 'react-icons/ri';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import Loader from '../../components/Loader/Loader';
import 'react-loading-skeleton/dist/skeleton.css';
import {
  AddCompetition,
  DeleteCompetitions,
} from '../../components/Modals/Modals';

const Competitions = () => {
  const navigate = useNavigate();
  const { showProfileModal, baseURL } = useGlobalContext();
  const [competitions, setCompetitions] = useState([]);
  const [loading, setLoading] = useState(true);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`));
  const [showAddClubModal, setShowAddClubModal] = useState(false);
  const [showDeleteClubsModal, setShowDeleteClubsModal] = useState(false);

  const fetchCompetitons = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`${baseURL}/competitions`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      setCompetitions(data.competitions);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCompetitons();
    // eslint-disable-next-line
  }, [showAddClubModal, showDeleteClubsModal]);

  const deleteClub = async (clubId) => {
    try {
      const { data } = await axios.delete(`${baseURL}/competitions/${clubId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success(data.msg);
      fetchCompetitons();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <main className='competitions-page'>
      <Navbar category={`Management >`} currentPage={`Competitions`} />
      <Sidebar activePage={11} />

      <div className={`main-content ${showProfileModal ? `back` : null}`}>
        <div className='title-btn'>
          <FaChevronLeft onClick={() => navigate(`/dashboard`)} />
          <h2 className='title'>Competitions</h2>
        </div>

        {loading ? (
          <Loader />
        ) : (
          <>
            <div className='add-delete-club'>
              <button
                className='primary'
                onClick={() => setShowAddClubModal(true)}
              >
                Add Competition
              </button>
              <button
                className='red'
                onClick={() => setShowDeleteClubsModal(true)}
              >
                Delete Competitions
              </button>
            </div>

            <section className='available-clubs'>
              <h2>Available Competitions</h2>

              {competitions.length > 0 ? (
                <div className='grid'>
                  {competitions.map((competition) => {
                    const { _id, competitionName, competitionLogo } =
                      competition;
                    return (
                      <div className='item' key={_id}>
                        <div className='img'>
                          <img
                            src={`https://api.crspredictions.com${competitionLogo}`}
                            alt={competitionName}
                          />
                        </div>
                        <p className='name'>{competitionName}</p>
                        <RiDeleteBin2Fill
                          className='del'
                          onClick={() => deleteClub(_id)}
                        />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className='no-club'>
                  <h1>No Competition yet</h1>
                </div>
              )}
            </section>
          </>
        )}
      </div>
      {showAddClubModal && <AddCompetition dismissFunc={setShowAddClubModal} />}
      {showDeleteClubsModal && (
        <DeleteCompetitions dismissFunc={setShowDeleteClubsModal} />
      )}
    </main>
  );
};

export default Competitions;
