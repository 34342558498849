import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';
import { useNavigate, useParams } from 'react-router-dom';
import { useGlobalContext } from '../../context/Context';
import axios from 'axios';
import { FaChevronLeft, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import spinner from '../../assets/purple-btn-spinner.svg';
import Loader from '../../components/Loader/Loader';
import moment from 'moment';
import { RiDeleteBin2Fill } from 'react-icons/ri';
import { FaEdit } from 'react-icons/fa';

const EditAccaTip = () => {
  const { id } = useParams();
  const { showProfileModal, baseURL } = useGlobalContext();
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`));
  const [ticketDetails, setTicketDetails] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [editMatch, setEditMatch] = useState(``);

  const fetchTicketDetails = async () => {
    try {
      const { data } = await axios.get(`${baseURL}/accumulator-tips/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTicketDetails(data.tip);
      setPageLoading(false);
    } catch (error) {
      setLoading(false);
      setPageLoading(false);
    }
  };

  useEffect(() => {
    fetchTicketDetails();
    //eslint-disable-next-line
  }, [id]);

  const formik = useFormik({
    initialValues: {
      odds: ``,
      matchTime: ``,
      stake: ``,
      win: ``,
      date: ``,
    },
    onSubmit() {
      updateTip();
    },
  });

  const updateTip = async () => {
    const { odds, stake, win, date } = formik.values;

    try {
      setLoading(true);
      const { data } = await axios.patch(
        `${baseURL}/accumulator-tips/${id}`,
        {
          odds: odds ? odds : ticketDetails.odds,
          stake: stake ? stake : ticketDetails.stake,
          win: win ? win : ticketDetails.win,
          date: date ? date : ticketDetails.date,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      toast.success(data.msg);
      navigate(`/accumulator-tips`);
    } catch (error) {
      console.log(error);
      toast.success(error.response.data.msg);
      setLoading(false);
    }
  };

  const {
    totalOdds,
    bookies: oldBookies,
    matches,
    stake,
    win,
    date,
  } = ticketDetails;

  const deleteBookie = async (bookieId) => {
    try {
      const { data } = await axios.patch(
        `${baseURL}/accumulator-tips/delete-bookie/${bookieId}`,
        { tipId: id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(data.msg);
      fetchTicketDetails();
    } catch (error) {
      toast.error(error.response.data.msg);
    }
  };

  return (
    <main className='edit-acca-tips-page'>
      <Navbar
        category={`Management > Free Accumulator Tips >`}
        categoryLink={`/accumulator-tips`}
        currentPage={`Edit Tip`}
      />
      <Sidebar activePage={2} />

      <div className={`main-content ${showProfileModal ? `back` : null}`}>
        <div className='title-btn'>
          <FaChevronLeft onClick={() => navigate(`/accumulator-tips`)} />
          <h2 className='title'>Edit Tip</h2>
        </div>

        {pageLoading ? (
          <div className='loader-container'>
            <Loader />
          </div>
        ) : (
          <>
            {/* TICKET INFORMATION BOX */}
            <div className='ticket-details'>
              <h3 className='title'>Tip Details</h3>
              <form action='' onSubmit={formik.handleSubmit}>
                {/* ODDS */}
                <div className='form-control'>
                  <label
                    htmlFor='odds'
                    className={
                      formik.errors.totalOdds && formik.touched.totalOdds
                        ? `error`
                        : ``
                    }
                  >
                    {formik.errors.totalOdds && formik.touched.totalOdds
                      ? formik.errors.totalOdds
                      : `Total Odds`}{' '}
                    <span>*</span>
                  </label>
                  <input
                    name='totalOdds'
                    id='totalOdds'
                    value={formik.values.totalOdds}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder={totalOdds}
                  />
                </div>

                {/* STAKE */}
                <div className='form-control'>
                  <label
                    htmlFor='stake'
                    className={
                      formik.errors.stake && formik.touched.stake ? `error` : ``
                    }
                  >
                    {formik.errors.stake && formik.touched.stake
                      ? formik.errors.stake
                      : `Stake`}{' '}
                    <span>*</span>
                  </label>
                  <input
                    name='stake'
                    id='stake'
                    value={formik.values.stake}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder={stake}
                  />
                </div>

                {/* POTENTIAL WIN */}
                <div className='form-control'>
                  <label
                    htmlFor='win'
                    className={
                      formik.errors.win && formik.touched.win ? `error` : ``
                    }
                  >
                    {formik.errors.win && formik.touched.win
                      ? formik.errors.win
                      : `Potential Win`}{' '}
                    <span>*</span>
                  </label>
                  <input
                    name='win'
                    id='win'
                    value={formik.values.win}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder={`₦${win}`}
                  />
                </div>

                {/* MATCH DATE */}
                <div className='form-control'>
                  <label
                    htmlFor='date'
                    className={
                      formik.errors.date && formik.touched.date ? `error` : ``
                    }
                  >
                    {formik.errors.date && formik.touched.date
                      ? formik.errors.date
                      : `Match Date`}
                  </label>
                  <input
                    name='date'
                    id='date'
                    type='date'
                    value={formik.values.date}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder={date}
                  />
                </div>

                {/* BOOKIES */}
                <div className='bookies current'>
                  <h3 className='title'>Bookies</h3>
                  {oldBookies?.map((bookie) => {
                    const { _id, name, code, link } = bookie;
                    return (
                      <div className='bookie' key={_id}>
                        <div className='name'>
                          <h5>{name}</h5>
                        </div>
                        <div className='code'>{code}</div>
                        <div className='link'>
                          <h5>{link}</h5>
                        </div>

                        <div className='delete'>
                          <RiDeleteBin2Fill onClick={() => deleteBookie(_id)} />
                        </div>
                      </div>
                    );
                  })}
                </div>

                {/* MATCHES */}
                <div className='matches'>
                  <h3 className='title'>Matches</h3>
                  {matches?.map((match) => {
                    const {
                      _id,
                      matchTime,
                      homeTeam,
                      awayTeam,
                      prediction,
                      status,
                      odds,
                      competitionName,
                    } = match;

                    return (
                      <div className='match' key={_id}>
                        <div className='teams'>
                          <h5>
                            {homeTeam} <span>vs</span> {awayTeam}
                          </h5>
                        </div>
                        <div>
                          <h5>{competitionName}</h5>
                        </div>
                        <div>
                          <h5>{prediction}</h5>
                        </div>
                        <div>
                          <h5>{odds} odds</h5>
                        </div>
                        <div>
                          <h5>{moment(matchTime).format(`lll`)}</h5>
                        </div>
                        <div>
                          <h5>Status: {status}</h5>
                        </div>

                        <div className='delete'>
                          <FaEdit onClick={() => setEditMatch(_id)} />
                        </div>
                      </div>
                    );
                  })}
                </div>

                {/* SUBMIT BUTTON */}
                <button type='submit' className='primary'>
                  Update Tip {loading ? <img src={spinner} alt='' /> : null}
                </button>
              </form>
            </div>
          </>
        )}
      </div>

      {editMatch && (
        <EditMatchModal
          resetFunc={setEditMatch}
          matchId={editMatch}
          tipId={id}
          refreshFunc={fetchTicketDetails}
        />
      )}
    </main>
  );
};

export const EditMatchModal = ({ resetFunc, matchId, tipId, refreshFunc }) => {
  const [status, setStatus] = useState(`pending`);
  const { baseURL } = useGlobalContext();
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`));
  const [loading, setLoading] = useState(false);

  const updateStatus = async () => {
    try {
      setLoading(true);
      const { data } = await axios.patch(
        `${baseURL}/accumulator-tips/update-match-status/${tipId}`,
        { matchId: matchId, status: status },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      toast.success(data.msg);
      resetFunc(``);
      refreshFunc();
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error(error.response.data.msg);
    }
  };

  return (
    <div className='modal-container'>
      <div className='modal add-bookie'>
        {/* CLOSE MODAL BTN */}
        <div className='close-btn'>
          <FaTimes
            onClick={() => {
              resetFunc(``);
            }}
          />
        </div>

        {/* STATUS */}
        <div className='form-control'>
          <label htmlFor='status'>Match Status</label>
          <select
            name='status'
            id='status'
            value={status}
            onChange={(e) => {
              setStatus(e.target.value);
            }}
          >
            <option defaultChecked hidden>
              Select status
            </option>
            <option value='pending' defaultValue={`pending`}>
              Pending
            </option>
            <option value='won'>Won</option>
            <option value='lost'>Lost</option>
          </select>
        </div>

        <div className='btn'>
          <button className='primary' onClick={() => updateStatus()}>
            Update Match {loading ? <img src={spinner} alt='' /> : null}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditAccaTip;
