import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';
import FunctionsBar from '../../components/Functions-Bar/FunctionsBar';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../context/Context';
import Loader from '../../components/Loader/Loader';
import axios from 'axios';
import moment from 'moment';
import { AiTwotoneEdit } from 'react-icons/ai';
import { RiDeleteBin2Fill } from 'react-icons/ri';
import { toast } from 'react-toastify';
import spinner from '../../assets/purple-btn-spinner.svg';

const Leaderboard = () => {
  const { showProfileModal, baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(``);
  const [leaderboards, setLeaderboards] = useState([]);
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`));
  const navigate = useNavigate();
  const [activeLeaderboard, setActiveLeaderboard] = useState([]);

  const getLeaderboards = async () => {
    try {
      setLoading(`page-loading`);
      const { data } = await axios.get(`${baseURL}/leaderboard`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(``);
      setLeaderboards(data.leaderboards);
      setActiveLeaderboard(data.leaderboards[0]);
    } catch (error) {
      setLoading(``);
    }
  };

  // CREATE LEADERBOARD
  const createLeaderboard = async () => {
    try {
      setLoading(`create-leaderboard-loading`);
      const { data } = await axios.post(
        `${baseURL}/leaderboard/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(data.msg);
      getLeaderboards();
      setLoading(``);
    } catch (error) {
      toast.success(error.response.data.msg);
      setLoading(``);
    }
  };

  // DELETE PLAYER
  const deletePlayer = async (id) => {
    try {
      await axios.delete(
        `${baseURL}/leaderboard/delete-player/${activeLeaderboard._id}/player/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(`Player deleted`);
      getLeaderboards();
    } catch (error) {
      toast.success(error.response.data.msg);
    }
  };

  // DELETE SINGLE LEADERBOARD
  const deleteLeaderboard = async () => {
    try {
      setLoading(`delete-leaderboard-loading`);
      const { data } = await axios.delete(
        `${baseURL}/leaderboard/${activeLeaderboard._id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(``);
      toast.success(data.msg);
      getLeaderboards();
    } catch (error) {
      setLoading(``);
      toast.success(error.response.data.msg);
    }
  };

  useEffect(() => {
    getLeaderboards();
    // eslint-disable-next-line
  }, []);

  return (
    <main className='leaderboard-page'>
      <Navbar category={`Menu >`} currentPage={`Leaderboard`} />
      <Sidebar activePage={5} />

      <div className={`main-content ${showProfileModal ? `back` : null}`}>
        <FunctionsBar>
          <button className='primary' onClick={createLeaderboard}>
            Create Leaderboard{' '}
            {loading === `create-leaderboard-loading` ? (
              <img src={spinner} alt='' />
            ) : null}
          </button>
          <button
            className='primary'
            onClick={() => navigate(`/leaderboard/add-player`)}
          >
            Add Player
          </button>
        </FunctionsBar>

        <div className='form-control leaderboard'>
          <label htmlFor='active-leaderboard'>Active Leaderboard</label>
          <select
            name='active-leaderboard'
            id='active-leaderboard'
            onChange={(e) =>
              setActiveLeaderboard(
                leaderboards.filter(
                  (leaderboard) => leaderboard.name === e.target.value
                )[0]
              )
            }
          >
            {leaderboards.map((leaderboard, index) => {
              return (
                <option key={index} value={leaderboard.name}>
                  {leaderboard.name}
                </option>
              );
            })}
          </select>
        </div>

        <div className='table'>
          <div className='headers'>
            <p>Point</p>
            <p>Username</p>
            <p>Date Created</p>
            <p>Actions</p>
          </div>

          {loading === `page-loading` ? (
            <Loader />
          ) : (
            <>
              {activeLeaderboard && (
                <>
                  {activeLeaderboard.players ? (
                    <>
                      {activeLeaderboard.players.map((player) => {
                        const { _id, points, username, createdAt } = player;

                        return (
                          <div className='player-info' key={_id}>
                            <p className='points'>{points}</p>

                            <p className='username'>{username}</p>

                            <p className='date'>
                              {moment(createdAt).format(`lll`)}
                            </p>
                            <div className='btns'>
                              <AiTwotoneEdit
                                className='edit'
                                onClick={() =>
                                  navigate(
                                    `/leaderboard/${activeLeaderboard._id}/player/${_id}`
                                  )
                                }
                              />
                              <RiDeleteBin2Fill
                                className='delete'
                                onClick={() => deletePlayer(_id)}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <div className='no-players'>
                      <h1>No players on this leaderboard yet</h1>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>

        <div className='delete-leaderboard'>
          <button className='red' onClick={deleteLeaderboard}>
            Delete Leaderboard
            {loading === `delete-leaderboard-loading` ? (
              <img src={spinner} alt='' />
            ) : null}
          </button>
        </div>
      </div>
    </main>
  );
};

export default Leaderboard;
